import React from 'react'

function ListIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#020317"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}>
      <path d="M80-160v-160h160v160H80Zm240 0v-160h560v160H320ZM80-400v-160h160v160H80Zm240 0v-160h560v160H320ZM80-640v-160h160v160H80Zm240 0v-160h560v160H320Z"/>
    </svg>
  )
}

export default ListIco