import React from 'react'

function ComboBox({label,value,  placeholder, options, onChange}) {
  return (
    <div className='flex gap-2 items-center'>
      <label className='text-sm  text-gray-500'>{label}</label>
      <select className='border-b-2 min-w-12 text-left' onChange={onChange} value={value}>
        {options.map((element,index) => (
          <option className='' key={index} value={element}>{element}</option>
        ))}
       
      </select>

    </div>
  )
}

export default ComboBox