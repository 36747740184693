import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/variables'; // URL de variables

// Get all variables (considering pagination, filtering and sorting)
export async function getVariables(page = 1, limit = 10, searchTerm = "", sortBy, token) {
  try {
    /*const response = await axios.get(API_BASE_URL, {
      params: {
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy
      },
    });
    return response.data;*/
    return getAllVariables(token);
  } catch (error) {
    throw new Error('Error getting variables: ' + error.message);
  }
}

// Get all variables (considering pagination, filtering and sorting)
export async function getAllVariables(token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error getting variables: ' + error.message);
  }
}

// Get all variables by Device
export async function getVariablesByDevice(deviceId, token) {
    try {
      const response = await axios.get(API_BASE_URL+'/getByDeviceId/'+deviceId,
        {
          headers: {Authorization: "Bearer "+token}
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('Error getting variables for Device: ' + deviceId + error.message);
    }
  }


// Get variable by ID
export async function getVariableById(variableId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${variableId}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data; 
  } catch (error) {
    console.error(`Error fetching variable with ID ${variableId}:`, error);
    return null; 
  }
}

// Get a variable by its name
export async function getVariableByName(variableName, token) {
  try {
    const response = await axios.get(API_BASE_URL+'/getByName/' + variableName,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return (response.data)?response.data:undefined;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return undefined;
    } else {
      throw new Error("Error fetching variable by name: " + error.message);
    }
  }
}

// Create a new variable
export async function createVariable(newVariable, token) {
  try {
    const response = await axios.post(API_BASE_URL, newVariable, 
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating variable: ' + error.message);
  }
}

// Update an existing variable
export async function updateVariable(updatedVariable, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedVariable._id}`, updatedVariable,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating variable: ' + error.message);
  }
}

// Delete an variable
export async function deleteVariable(deletedVariable, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedVariable._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting variable: ' + error.message);
  }
}
