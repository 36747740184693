import React, { useState, useRef, useEffect } from 'react';
import CalendarIco from './icons/CalendarIco';

// Función para generar los días del mes
const generateCalendar = (year, month) => {
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  let daysArray = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    daysArray.push(null); // Espacios vacíos antes del primer día
  }

  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(day);
  }

  return daysArray;
};

const months = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

const DatePicker = ({ onDateChange, text, value, error = "" }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(value || new Date());
  const [currentDate, setCurrentDate] = useState(value || new Date());
  const [selectedTime, setSelectedTime] = useState({
    hours: value?.getHours() || new Date().getHours(),
    minutes: value?.getMinutes() || new Date().getMinutes(),
  });
  const [displayDate, setDisplayDate] = useState('');
  const [showYearSelector, setShowYearSelector] = useState(false);
  const datePickerRef = useRef(null);

  const currentYear = new Date().getFullYear();
  const yearsArray = Array.from(
    { length: 11 }, // 11 para incluir el año actual + 10 años atrás
    (_, i) => currentYear - i
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChangeDisplayDate = () => {
    const formattedSelectedDate = selectedDate
      ? `${selectedDate.toLocaleDateString()} ${selectedDate.getHours().toString().padStart(2, '0')}:${selectedDate.getMinutes().toString().padStart(2, '0')}`
      : 'Selecciona una fecha';
    setDisplayDate(formattedSelectedDate);
  };

  const handleDateClick = (day) => {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day,
      selectedTime.hours,
      selectedTime.minutes
    );
    setSelectedDate(newDate);
    setShowCalendar(false);
    if (onDateChange) onDateChange(newDate); // Llama a onDateChange inmediatamente
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };

  const handleYearClick = () => {
    setShowYearSelector(!showYearSelector);
  };

  const handleYearSelect = (year) => {
    setCurrentDate(new Date(year, currentDate.getMonth()));
    setShowYearSelector(false);
  };

  const handleTimeChange = (type, value) => {
    const updatedTime = {
      ...selectedTime,
      [type]: value,
    };
    setSelectedTime(updatedTime);

    // Actualiza selectedDate directamente con la nueva hora y minutos
    const newDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      type === 'hours' ? value : selectedTime.hours,
      type === 'minutes' ? value : selectedTime.minutes
    );

    setSelectedDate(newDate);
    if (onDateChange) onDateChange(newDate); // Llama a onDateChange inmediatamente
  };

  const daysArray = generateCalendar(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

  const prevMonthAbbr = months[(currentDate.getMonth() - 1 + 12) % 12].slice(0, 3);
  const nextMonthAbbr = months[(currentDate.getMonth() + 1) % 12].slice(0, 3);

  useEffect(() => {
    // Cada vez que value cambia, se actualiza selectedDate y selectedTime
    if (value) {
      setSelectedDate(value);
      setSelectedTime({
        hours: value.getHours(),
        minutes: value.getMinutes(),
      });
    }
  }, [value]);

  useEffect(() => {
    handleChangeDisplayDate();
  }, [selectedDate]);

  return (
    <div className={"relative inline-block mb-5 w-full" + (error ? " animate-shake" : "")}ref={datePickerRef}>
      <label
        className={`text-gray-500 duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:left-4 ${showCalendar || showYearSelector ? "text-blue-900" : ""} peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:font-medium absolute`}
      >
        {text}
      </label>
      <div
        className={`border-2 ${error ? "border-red_error": "border-gray-300"} py-3.5 px-4 w-full cursor-pointer rounded-md ${error ? "text-red_error" : "text-gray-900"} bg-transparent ${showCalendar || showYearSelector ? "outline-none ring-0 border-blue-900" : ""} peer`}
        onClick={() => setShowCalendar(!showCalendar)}
      >
        {displayDate}
      </div>
      {showCalendar && (
        <div className="absolute mt-2 p-4 bg-white border rounded shadow-lg z-10">
          <div className="flex justify-between items-center mb-2">
            <button onClick={handlePrevMonth} className="text-blue-500 mx-1">
              {prevMonthAbbr}
            </button>
            <span className="font-semibold cursor-pointer" onClick={handleYearClick}>
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </span>
            <button onClick={handleNextMonth} className="text-blue-500 mx-1">
              {nextMonthAbbr}
            </button>
          </div>

          {showYearSelector ? (
            <div className="grid grid-cols-3 gap-2 mb-4" style={{ width: '300px' }}>
              {yearsArray.map((year) => (
                <button
                  key={year}
                  className={`p-2 border rounded ${year === currentDate.getFullYear() ? 'bg-bluesens text-white' : 'bg-white hover:bg-blue-100'}`}
                  onClick={() => handleYearSelect(year)}
                >
                  {year}
                </button>
              ))}
            </div>
          ) : (
            <>
              <div className="grid grid-cols-7 gap-2">
                {daysOfWeek.map((day) => (
                  <div key={day} className="text-center font-semibold">
                    {day}
                  </div>
                ))}
                {daysArray.map((day, index) => (
                  <div
                    key={index}
                    className={`text-center p-2 cursor-pointer ${day ? 'rounded' : 'bg-transparent pointer-events-none'} ${day === selectedDate.getDate() ? 'bg-bluesens text-white' : 'hover:bg-blue-100 '}`}
                    onClick={() => day && handleDateClick(day)}
                  >
                    {day}
                  </div>
                ))}
              </div>

              {!showYearSelector && (
                <div className="flex justify-center items-center space-x-2 mt-4">
                  <div>
                    <label className="block text-sm text-gray-500">Hora</label>
                    <input
                      type="number"
                      min="0"
                      max="23"
                      value={selectedTime.hours}
                      onChange={(e) => handleTimeChange('hours', Math.min(23, Math.max(0, parseInt(e.target.value) || 0)))}
                      className="border p-1 rounded w-16"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500">Minutos</label>
                    <input
                      type="number"
                      min="0"
                      max="59"
                      value={selectedTime.minutes}
                      onChange={(e) => handleTimeChange('minutes', Math.min(59, Math.max(0, parseInt(e.target.value) || 0)))}
                      className="border p-1 rounded w-16"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
