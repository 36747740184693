import { React, useState } from "react";

const SelectInput = ({
  label,
  id,
  options,
  value,
  onChange,
  error = "",
  required = false,
  enabled = true,
  showErrors = false,
  placeholder = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={
        "relative z-0 w-fit group" +
        (error && showErrors ? "mb-5 animate-shake" : "")
      }
    >
      <select
        className={`block py-2.5 px-4 pr-8 w-full text-gray-900 bg-transparent border-2 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-900 peer ${
          error &&
          showErrors &&
          "text-red_error border-red_error focus:border-red_error"
        }`}
        name={id}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        disabled={!enabled}
        onClick={() => setIsOpen(!isOpen)}
        onBlur={() => setIsOpen(false)}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <img
          className={`w-4 h-4 transition-transform duration-300 ${isOpen ? "rotate-180" : ""}`}
          alt="drop_down_menu"
          src={`${process.env.PUBLIC_URL}/svg/arrow_down.svg`}
        />
      </div>
      <label
        htmlFor={id}
        className={`pointer-events-none peer-focus:font-medium absolute text-gray-500 duration-300 transform top-3 left-3 origin-[0] peer-focus:left-4 peer-focus:text-blue-900 bg-white px-1 ${"-translate-y-6 scale-75 peer-focus:scale-75 peer-focus:-translate-y-6"} ${
          error && showErrors && "text-red_error peer-focus:text-red_error"
        }`}
      >
        {label}
      </label>
      {error && showErrors && (
        <div className="relative pb-1">
          <span className="px-1 absolute text-red_error">{error}</span>
        </div>
      )}
    </div>
  );
};

export default SelectInput;
