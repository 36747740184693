import React, { useRef, useState } from 'react';

const generatePastelColor = (index) => {
  const hue = (index * 360) / 10;
  return `hsl(${hue}, 70%, 80%)`;
};

// Componente de gráfico de pastel
const PieChartWidget = ({ data }) => {
  data = [
    { label: 'AAAAAAAAAA', value: 30 },
    { label: 'B', value: 70 },
    { label: 'C', value: 30 },
    { label: 'D', value: 60 },
  ];
  const [tooltip, setTooltip] = useState({ show: false, label: '', value: '', color: '', top: '0px', left: '0px' });
  const [hoveredIndex, setHoveredIndex] = useState(null); // Estado para saber cuál segmento está activo
  const chartRef = useRef(null);

  // Total de los valores para calcular proporciones
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);

  // Cálculo de los ángulos de los segmentos
  const calculateAngles = (data) => {
    let startAngle = 0;
    return data.map((item) => {
      const endAngle = (item.value / totalValue) * 360;
      const segment = { startAngle, endAngle: startAngle + endAngle };
      startAngle += endAngle;
      return segment;
    });
  };

  const segments = calculateAngles(data);

  // Mostrar tooltip
  const handleMouseEnter = (event, label, value, color, index) => {
    const { clientX, clientY } = event;
    const rect = chartRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;

    setTooltip({
      show: true,
      label,
      value,
      color,
      top: `${offsetY + 10}px`,
      left: `${offsetX + 10}px`,
    });

    setHoveredIndex(index); // Setea el índice del segmento activo
  };

  const handleMouseLeave = () => {
    setTooltip({
      show: false,
      label: '',
      value: '',
      color: '',
      top: '0px',
      left: '0px',
    });

    setHoveredIndex(null); // Resetea el índice del segmento activo
  };

  return (
    <div className="relative w-full h-full pb-20" ref={chartRef}>
      <div className="relative top-0 left-0 p-2 bg-white border border-gray-300 flex flex-wrap">
        {data.map((item, index) => {
          const percentage = ((item.value / totalValue) * 100).toFixed(1);
          return (
            <div key={item.label} className="flex items-center mb-2 mr-4">
              <div
                className="w-4 h-4 rounded-full"
                style={{ backgroundColor: generatePastelColor(index) }}
              ></div>
              <span className="ml-2">{item.label}: {percentage}%</span>
            </div>
          );
        })}
      </div>
      <svg viewBox="0 0 32 32" className="p-5 w-full h-full">
        {data.map((item, index) => {
          const { startAngle, endAngle } = segments[index];
          const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;
          const start = {
            x: Math.cos((Math.PI / 180) * startAngle) * 16 + 16,
            y: Math.sin((Math.PI / 180) * startAngle) * 16 + 16,
          };
          const end = {
            x: Math.cos((Math.PI / 180) * endAngle) * 16 + 16,
            y: Math.sin((Math.PI / 180) * endAngle) * 16 + 16,
          };

          // Calcular el centro del segmento para colocar el texto
          const midAngle = (startAngle + endAngle) / 2;
          const mid = {
            x: Math.cos((Math.PI / 180) * midAngle) * 10 + 16,
            y: Math.sin((Math.PI / 180) * midAngle) * 10 + 16,
          };

          const percentage = ((item.value / totalValue) * 100).toFixed(1);

          // Calcular la escala para la animación
          const isHovered = hoveredIndex === index;
          const scale = isHovered ? 1.04 : 1; // Aumenta el tamaño en un 10%
          const transformOrigin = `${16}px ${16}px`; // Centrar la animación

          return (
            <g
              key={item.label}
              transform={`scale(${scale})`}
              style={{
                transformOrigin,
                transition: 'transform 0.2s ease-out', // Transición suave
              }}
            >
              <path
                d={`M16,16 L${start.x},${start.y} A16,16 0 ${largeArcFlag},1 ${end.x},${end.y} Z`}
                fill={generatePastelColor(index)}
                onMouseEnter={(e) => handleMouseEnter(e, item.label, item.value, generatePastelColor(index), index)}
                onMouseLeave={handleMouseLeave}
              />
              <text
                x={mid.x}
                y={mid.y}
                dominantBaseline="middle"
                textAnchor="middle"
                fill="white"
                fontSize="3.5"
                fontWeight="bold"
                style={{ pointerEvents: 'none' }}
              >
                {item.value}
              </text>
            </g>
          );
        })}
      </svg>

      {tooltip.show && (
        <div
          className="absolute transition-opacity duration-300"
          style={{
            top: tooltip.top,
            left: tooltip.left,
            transform: 'translate(-50%, 0)',
            pointerEvents: 'none',
            opacity: tooltip.show ? 1 : 0,
          }}
        >
          <div className="flex items-center px-2 py-1 text-white" style={{ backgroundColor: 'black', borderRadius: '4px', opacity: 0.8 }}>
            <div className="w-3 h-3 rounded-full" style={{ backgroundColor: tooltip.color }}></div>
            <span className="ml-2">{tooltip.label}: {tooltip.value} ({((tooltip.value / totalValue) * 100).toFixed(1)}%)</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChartWidget;
