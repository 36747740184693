import React, { useEffect, useState } from "react";
import CollapsibleSection from "../../../components/CollapsibleSection";
import TrashIco from "../../../components/icons/TrashIco";
import AddCircle from "../../../components/icons/AddCircle";
import CheckIco from "../../../components/icons/CheckIco";
import { useProcesses } from "../../../providers/ProcessesContext";
import { useAuth } from "../../../providers/AuthContext";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../providers/AppContext";
import { getProcessById, updateProcess } from "../../../services/apiProcess";
import { getAllParameters } from "../../../services/apiProcessParameteres";

import Spinner from "../../../components/Spinner";
import SpinnerText from "../../../components/SpinnerText";

function EditTableSettings({toggleModal, refreshTable}){
  const [tableSettings, setTableSettings] = useState([]);
  const [config, setConfig] = useState([]);
  const {auth} = useAuth();
  const {addToast} = useAppContext();
  const navigate = useNavigate();
  const {selectedOption} = useProcesses(); 
  //backend flags
  const [fetchingProcess, setFetchingProcess] = useState(true);
  const [fetchingParameters, setFetchingParameters] = useState(true);
  const [updatingParameters, setUpdatingParameters] = useState(false);

  const refreshProcessPage = () => {
    async function fetchProcess(){
      const process = await getProcessById(selectedOption, auth?.token);
      return process;
    }
    try {
      setFetchingProcess(true);
      fetchProcess().then(response => {
        console.log(console.log(response.tableSettings))
        setTableSettings(response.tableSettings);
        setFetchingProcess(false);
      }).catch(error => {
        if (error.response.status === 404){
          addToast("Proceso no encontrado",3)
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
        }
        else{
          navigate('/logout')
        }
      })
    } catch (error) {
      console.log(error, "a", typeof(error));
      setFetchingProcess(false);
    }
  }

  const refreshParameters = () => {
    async function fetchParameters(){
      const parameters = await getAllParameters(selectedOption, auth?.token);
      return parameters;
    }
    try {
      setFetchingParameters(true);
      fetchParameters().then(response => {
        console.log(console.log(response))
        setConfig(response.map(parameter => {
          return {_id: parameter._id, key: parameter.key, alias: parameter.alias}
        }));
        setFetchingParameters(false);
      }).catch(error => {
        if (error.response.status === 404){
          addToast("Proceso no encontrado",3)
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
        }
        else if (error.response.status === 419){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        else{
          navigate('/logout')
        }
      })
    } catch (error) {
      console.log(error, "a", typeof(error));
      setFetchingParameters(false);
    }
  }
  
  const handleSaveConfig = () => {
    async function updateconfig(){
      console.log(tableSettings)
      const processUpdated = await updateProcess({
        _id: selectedOption,
        tableSettings
      }, auth?.token);
      return processUpdated;
    }
    try {
      setUpdatingParameters(true);
      updateconfig().then(response => {
        console.log(console.log(response))
        setConfig(response);
        setUpdatingParameters(false);
        toggleModal();
        addToast("Configuración guardada")
        refreshTable();
      }).catch(error => {
        if (error.response.status === 400){
          addToast("Configuracìon vacia",3)
          setUpdatingParameters(false);
        }
        else if (error.response.status === 404){
          addToast("Proceso no encontrado",3)
        }
        else if (error.response.status === 419){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
        }
        else{
          navigate('/logout')
        }
      })
    } catch (error) {
      console.log(error, "a", typeof(error));
      setUpdatingParameters(false);
    }
  }
  useEffect( () => {
    console.log("traer info del ", selectedOption);
    refreshProcessPage();
    refreshParameters();
  }, [selectedOption]);

  return (
    <div className='flex flex-col gap-2 h-[35rem] relative overflow-auto'>
      { (fetchingProcess || fetchingParameters) && (
        <div className="flex justify-center h-full items-center">
          <Spinner />
        </div>
      )}
      { !fetchingProcess && !fetchingParameters && (
        <>
          <div className=' flex flex-col flex-grow gap-2'>
            {updatingParameters && (
              <div className="flex relative h-10 bg-gray-200 w-full justify-center items-center transition-max-height duration-150 ease-in-out">
                <div className="absolute">
                  <SpinnerText text="Guardando..." />
                </div>
              </div>
            )}
            { config.length === 0 && (
              <div className="flex relative h-10 bg-yellow-200 w-full justify-center items-center transition-max-height duration-150 ease-in-out">
                <div className="absolute">
                  <span className="text-gray-600 font-semibold">
                  Debe crear parámetros en la sección de configuración antes de usar esta opción
                  </span>
                </div>
              </div>
            )}
            <CollapsibleSection title={"Columnas a mostrar"} titleBgColor='bg-lightblue' titleTextColor='text-bluesens' open={true} border={false}>
              {tableSettings.length > 0 && (
                <div className="px-4 grid grid-cols-[2.5rem_1fr_auto] gap-2 w-full">
                  <div className="flex items-center justify-center">Orden</div>
                  <span className="text-left text-sm">Nombre de la columna</span>
                  <div></div>
                  {tableSettings.map((element, index) => (
                    <React.Fragment key={index}>
                      <div className="flex items-center justify-center">{index + 1}</div>
                      <span className="text-left">{element.alias || element.key}</span>
                      <button onClick={() => {
                        let tempSet = [...tableSettings]
                        tempSet.splice(index,1)
                        setTableSettings(tempSet)
                        }}>
                        <TrashIco fill='red' />
                      </button>
                      
                    </React.Fragment>
                  ))}
              </div>)}
              { tableSettings.length === 0 && (
                <div className=" flex mx-5">
                  <span>No hay columnas a mostrar</span>
                </div>
              )}
            </CollapsibleSection>

            <CollapsibleSection title={"Columnas ocultas"} titleBgColor='bg-lightblue' titleTextColor='text-bluesens' open={true} border={false}>
              {config.length > 0 && (
                <div className="px-4 grid grid-cols-[2.5rem_1fr_auto] gap-2 w-full">
                  {config
                    .filter(element => !tableSettings.some(setting => setting.key === element.key))
                    .map((element, index) => (
                      <React.Fragment key={index}>
                        <div className="flex items-center justify-center"></div>
                        <span className="text-left">{element.alias || element.key}</span>
                        <button onClick={() => {setTableSettings([...tableSettings, element])}}>
                          <AddCircle />
                        </button>
                      </React.Fragment>
                    ))}
                </div>
              )}
              { config.length === 0 && (
                <div className=" flex mx-5">
                  <span>No hay columnas a mostrar</span>
                </div>
              )}
            </CollapsibleSection>
            </div>
            {/* Span fijo al final del contenedor */}
            <div className='flex-none sticky bottom-0 bg-white p-2 shadow-lg'>
              <div className='flex w-full justify-end'>
                {!updatingParameters && config.length !== 0  &&(
                  <button className='' onClick={handleSaveConfig} disabled={tableSettings.length === 0}>
                    <CheckIco width='50px' height='50px' fill={`${tableSettings.length === 0 ? "gray" : "bluesens"}`}/>
                  </button>
                )}
                  
              </div>
            </div>
        </>
      )}
      
    </div>
  )
}

export default EditTableSettings