import React from 'react'

function TitleBar({text}) {
  return (
    <div className='bg-black w-full px-4 h-full flex '>
      <span className='text-white my-auto'>{text}</span>
    </div>
  )
}

export default TitleBar