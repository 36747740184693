import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";

import {
  getDeviceById,
  getDeviceByName,
  updateDevice,
} from "../../../services/apiDevices";

import {
  getVariablesByDevice,
  createVariable,
} from "../../../services/apiVariables";
import Spinner from "../../../components/Spinner";

import VariableCard from "../../variables/components/VariableCard.jsx";

import { formatDate } from "../../utils.js";
import { useAuth } from "../../../providers/AuthContext.js";


function DeviceDetails() {
  const {auth} = useAuth();
  const navigate = useNavigate();

  const { deviceId } = useParams();
  const [device, setDevice] = useState(null);
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [type, setType] = useState("");
  const [variables, setVariables] = useState([]);
  const typeOptions = ["Default", "GPS"];

  const [errorMessageName, setErrorMessageName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const refreshVariables = useCallback(async () => {
    try {
      getVariablesByDevice(deviceId, auth?.token).then(variablesData => {
        setVariables(variablesData);
      }).catch(error =>
        navigate('/logout')
      );
      
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  }, [deviceId]);

  useEffect(() => {
    refreshVariables();
  }, [refreshVariables]);

  useEffect(() => {
    setIsFormValid(name !== "" && !errorMessageName);
  }, [name, errorMessageName]);

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    async function fetchDevice(deviceId) {
      getDeviceById(deviceId, auth?.token).then(deviceData => {
        setDevice(deviceData);
        setName(deviceData?.name);
        setAlias(deviceData?.alias);
        setType(deviceData?.type);
      }).catch(error =>
        navigate('/logout')
      );
    }
    fetchDevice(deviceId);
  }, [deviceId]);

  useEffect(() => {
    async function validateName() {
      if (name) {
        getDeviceByName(name, auth?.token).then(found_device => {
          const exist = typeof found_device !== "undefined";
          if (!exist || name.toLowerCase() === device?.name.toLowerCase())
            setErrorMessageName("");
          else {
            setErrorMessageName("Este nombre de dispositivo ya esta en uso");
          }
        }).catch(error => 
          navigate('/logout')
        );
      } else {
        setErrorMessageName("");
      }
    }
    validateName();
  }, [name, device]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let info = { name, type, alias };
    try {
      await updateDevice({ ...device, ...info }, auth?.token).then(response => {
        setSuccessMessage("Dispositivo editado exitosamente!");
        setShowSuccessModal(true);
      }).catch( error => 
        navigate('/logout')
      );
    } catch (error) {
      setErrorMessage(
        "Error al editar dispositivo. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
        setSuccessMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessModal]);

  useEffect(() => {
    if (showErrorModal) {
      const timer = setTimeout(() => {
        setShowErrorModal(false);
        setErrorMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showErrorModal]);

  if (!device) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const handleCreateVariable = async () => {
    try {
      const lastVariable = variables && variables.at(-1);
      let newVariableName = "Nueva_Variable1";
      if (lastVariable && lastVariable.name.includes("Nueva_Variable")) {
        newVariableName =
          "Nueva_Variable" + (parseInt(lastVariable.name.slice(-1)) + 1);
      }
      createVariable({ deviceId, name: newVariableName }, auth?.token).then(response => {
        setSuccessMessage("Variable creada exitosamente!");
        setShowSuccessModal(true);
        refreshVariables();
      }).catch(error => 
        navigate('/logout')
      );
      
    } catch (error) {
      setErrorMessage(
        "Error al crear variable. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    }
  };

  return (
    <div className="p-4 flex flex-col">
      <div>
        <button
          onClick={handleBackClick}
          className="btn btn-secondary flex items-center transform hover:-translate-x-2 transition duration-300"
        >
          <img
            src={`${process.env.PUBLIC_URL}/svg/arrow_back.svg`}
            alt="Previous Page"
            className="h-5 w-5"
          />
          Regresar
        </button>
      </div>
      <div className="p-4 flex">
        <div className="flex flex-col mb-4 w-1/3 gap-4 bg-white rounded-lg shadow p-4">
          <h2 className="text-2xl font-semibold text-center">Información del Dispositivo</h2>
          <TextInput
            label="Nombre"
            id="device_name"
            value={name}
            onChange={(e) => setName(e.target.value.trim().replace(/\s/g, ""))}
            error={errorMessageName}
          />
          <TextInput
            label="Alias"
            id="device_alias"
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
          />
          <TextInput
            label="Última actividad"
            id="device_lastActivity"
            value={formatDate(device.lastVersionUpdateAt)}
            enabled={false}
            AppendClassName="bg-gray-100 cursor-not-allowed"
          />
          <SelectInput
            label="Tipo"
            id="device_type"
            options={typeOptions.slice(1)}
            value={type}
            onChange={(e) => setType(e.target.value)}
            placeholder={typeOptions[0]}
          />
          <button
            type="submit"
            id="submit-btn-role-form"
            className={`bg-bluesens text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:pointer-events-none`}
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            <span className="animate-pulse">Guardar</span>
          </button>
        </div>
        <div className="flex flex-col text-center w-2/3 p-4">
          <h2 className="text-xl">Añadir variables al dispositivo</h2>
          <div className="grid grid-cols-4 gap-4 mt-4 p-4 bg-white rounded-lg shadow-md">
            <div
              className={
                "p-4 flex flex-col justify-center items-center border-dashed border-4 border-bluesens rounded-xl h-48 w-full" +
                " cursor-pointer transform transition-transform hover:scale-110"
              }
              onClick={handleCreateVariable}
            >
              <img
                className="w-16"
                alt="add icon"
                src={`${process.env.PUBLIC_URL}/svg/addIco.svg`}
              />
              <p className="text-xl font-semibold mb-2">Añadir Variable</p>
            </div>
            <div className={
              "p-4 flex flex-col justify-center items-center border-dashed border-4 border-bluesens rounded-xl h-48 w-full" +
              " cursor-pointer transform transition-transform hover:scale-110"
            }>
              <img
                className="w-16"
                alt="add icon"
                src={`${process.env.PUBLIC_URL}/svg/functionsIco.svg`}
              />
              <p className="text-xl font-semibold mb-2">
                Añadir Variable Sintética
              </p>
            </div>
            {variables.length > 0 &&
              variables.map((variable) => (
                <VariableCard key={variable._id} variable={variable} appendClass={"h-48 w-ful" +
                  " cursor-pointer transform transition-transform hover:scale-110"} />
              ))}
          </div>
        </div>
      </div>
      <>
        {/* Success Modal */}
        <Modal
          title="Éxito"
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          colorTitle="bg-green-300"
          size="w-fit"
          showCloseButton={false}
        >
          <div className="text-center">
            <p>{successMessage}</p>
          </div>
        </Modal>
        {/* Error Modal */}
        <Modal
          title="Error"
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          colorTitle="bg-red-300"
          size="w-fit"
          showCloseButton={false}
        >
          <div className="text-center">
            <p>{errorMessage}</p>
          </div>
        </Modal>
      </>
    </div>
  );
}

export default DeviceDetails;
