import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/devices'; // URL de devices

// Get all devices (considering pagination, filtering and sorting)
export async function getDevices(page = 1, limit = 10, searchTerm = "", sortBy, token) {
  try {
    /*const response = await axios.get(API_BASE_URL, {
      params: {
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy
      },
    });
    return response.data;*/
    return getAllDevices(token);
  } catch (error) {
    throw new Error('Error getting devices: ' + error.message);
  }
}

// Get all devices (considering pagination, filtering and sorting)
export async function getAllDevices(token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error getting devices: ' + error.message);
  }
}

// Get device by ID
export async function getDeviceById(deviceId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/getById/${deviceId}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data; 
  } catch (error) {
    console.error(`Error fetching device with ID ${deviceId}:`, error);
    return null; 
  }
}

// Get a device by its name
export async function getDeviceByName(deviceName, token) {
  try {
    const response = await axios.get(API_BASE_URL+'/getByName/' + deviceName,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return (response.data)?response.data:undefined;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return undefined;
    } else {
      throw new Error("Error fetching device by name: " + error.message);
    }
  }
}

// Create a new device
export async function createDevice(newDevice, token) {
  try {
    const response = await axios.post(API_BASE_URL+'/', newDevice,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating device: ' + error.message);
  }
}

// Update an existing device
export async function updateDevice(updatedDevice, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedDevice._id}`, updatedDevice,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating device: ' + error.message);
  }
}

// Delete an device
export async function deleteDevice(deletedDevice, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedDevice._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting device: ' + error.message);
  }
}
