import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from "./providers/AuthContext";
import AppRoutes from "./routes";
import { useAppContext } from "./providers/AppContext";
import Toaster from "./components/Toaster";

function App() {
  const {toasts, removeToast} = useAppContext();
  return (
    <AuthProvider>
      {/* Renderizando los toasters */}
      {toasts.map((toast) => (
        <Toaster
          key={toast.id}
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={() => removeToast(toast.id)}
        />
      ))}
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
