import React, { useState, useEffect, useCallback } from "react";
import Container from "../../../layouts/Container";
import Modal from "../../../components/Modal";
import SearchBar from "../../../components/SearchBar";
import OrganizationForm from "../components/OrganizationForm";
import OrganizationTable from "../components/OrganizationTable";
import ButtonForm from "../../../components/ButtonForm";
import Spinner from "../../../components/Spinner";

import {
  getOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
} from "../../../services/apiOrganizations";

import { useAuth } from "../../../providers/AuthContext";
import { useNavigate } from "react-router-dom";

function Organizations() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [organizationToEdit, setOrganizationToEdit] = useState(null);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [organizationsPerPage, setOrganizationsPerPage] = useState(10);
  const pageSizes = [2, 5, 10, 20]; // Available page size options
  const [totalOrganizations, setTotalOrganizations] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false); // For create action
  const [isUpdating, setIsUpdating] = useState(false); // For update action
  const [isDeleting, setIsDeleting] = useState(false); // For delete action

  const [sortBy, setSortBy] = useState(
    JSON.stringify({ column: "createdAt", isAscending: true })
  );

  const refreshOrganizations = useCallback(async () => {
    setIsLoading(true); // Start loading
    try {
      getOrganizations(
        currentPage,
        organizationsPerPage,
        searchTerm,
        sortBy,
        auth?.token
      ).then(response => {
        const {records, totalPages, totalRecords} = response
        setOrganizations(records);
        setTotalOrganizations(totalRecords);
        setTotalPages(totalPages);
      }).catch( error => 
        navigate('/logout')
      );
    } catch (error) {
      console.error("Error fetching organizations:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  }, [currentPage, organizationsPerPage, searchTerm, sortBy]);

  useEffect(() => {
    refreshOrganizations();
  }, [refreshOrganizations]);

  const handleCreateOrganization = async (newOrganization) => {
    setIsCreating(true);
    try {
      createOrganization(newOrganization, auth?.token).then(response => {
        setShowForm(false);
        refreshOrganizations();
        setSuccessMessage("Organización creada exitosamente!");
        setShowSuccessModal(true);
      }).catch(error => 
        navigate('/logout')
      );
    } catch (error) {
      console.error("Error al crear organización:", error);
      setErrorMessage(
        "Error al crear organización. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    } finally {
      setIsCreating(false);
    }
  };

  const handleEditOrganization = (organization) => {
    setIsUpdating(true);
    setOrganizationToEdit(organization);
    setShowForm(true);
    setIsUpdating(false);
  };

  const handleCompleteEditing = async (updatedOrganization) => {
    setIsUpdating(true);
    try {
      updateOrganization(updatedOrganization, auth?.token).then(response =>{
        setOrganizationToEdit(null);
        setShowForm(false);
        refreshOrganizations();
        setSuccessMessage("Organización editada exitosamente!");
        setShowSuccessModal(true);
      }).catch( error => 
        navigate('/logout')
      );
    } catch (error) {
      console.error("Error al actualizar organización:", error);
      setErrorMessage(
        "Error al editar organización. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteOrganization = (organization) => {
    setOrganizationToDelete(organization);
    setShowDeleteConfirmation(true);
  };

  const handleCompleteDeleting = async () => {
    setIsDeleting(true);
    try {
      deleteOrganization(organizationToDelete, auth?.token).then(response => {
        setOrganizationToDelete(null);
        setShowDeleteConfirmation(false);
        refreshOrganizations();
        setSuccessMessage("Organización eliminada exitosamente!");
        setShowSuccessModal(true);
      }).catch( error => 
        navigate('/logout')
      );
      
    } catch (error) {
      console.error("Error al eliminar organización:", error);
      setErrorMessage(
        "Error al eliminar organización. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const startPage =
    currentPage === totalPages
      ? Math.max(1, currentPage - 2)
      : Math.max(1, currentPage - 1);
  const endPage = Math.min(totalPages, startPage + 2);

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
        setSuccessMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessModal]);

  useEffect(() => {
    if (showErrorModal) {
      const timer = setTimeout(() => {
        setShowErrorModal(false);
        setErrorMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showErrorModal]);

  const handleHeaderClick = (column) => {
    const currentSort = JSON.parse(sortBy);
    const newIsAscending =
      currentSort.column === column ? !currentSort.isAscending : true;
    setSortBy(JSON.stringify({ column, isAscending: newIsAscending }));
    setCurrentPage(1);
  };

  return (
    <Container>
      <div className="w-11/12 mx-auto p-4 my-2">
        <div className="flex items-center justify-between my-2">
          <SearchBar
            type="search"
            text="Filtrar por nombre"
            id="search"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <ButtonForm
            text="Crear Organización"
            enabled={true}
            action={() => setShowForm(true)}
          />
        </div>

        <Modal
          isOpen={showForm}
          onClose={() => {
            setShowForm(false);
            setOrganizationToEdit(null);
          }}
          title={
            organizationToEdit
              ? `Editar Organización " ${organizationToEdit?.name} "`
              : "Crear Organización"
          }
        >
          {isUpdating ? (
            <div className="flex justify-center items-center h-screen">
              <Spinner />
            </div>
          ) : (
            <OrganizationForm
              onSubmit={
                organizationToEdit
                  ? handleCompleteEditing
                  : handleCreateOrganization
              }
              organization={organizationToEdit}
            />
          )}
        </Modal>

        {isLoading && !organizations? (
          <div className="flex justify-center items-center h-screen">
            <Spinner />
          </div>
        ) : (
          <OrganizationTable
            organizations={organizations}
            onEdit={handleEditOrganization}
            onDelete={handleDeleteOrganization}
            onSort={handleHeaderClick} // Pass the sorting function to the table
            sortBy={sortBy} // Pass current sort state to the table
          />
        )}

        <div className="mt-4 flex justify-between items-center">
          <div className="flex justify-between items-center">
            <p className="mr-2">Total de organizaciones:</p>
            <span
              className={`text-3xl font-bold transition-all duration-500 text-bluesens`}
            >
              {totalOrganizations}
            </span>
          </div>
          {organizations && organizations.length > 0 && (
            <div className="flex flex-col justify-center gap-y-2 items-center">
              <span>Navegación entre Páginas</span>
              <div className="flex gap-x-1 items-center border-2 rounded p-2">
                <button
                  onClick={() => paginate(1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/skip_previous.svg`}
                    alt="First Page"
                    className="h-5 w-5"
                  />
                </button>

                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/arrow_back.svg`}
                    alt="Previous Page"
                    className="h-5 w-5"
                  />
                </button>

                {Array.from({ length: endPage - startPage + 1 }, (_, index) =>
                  index + 1 <= endPage - startPage ||
                  totalPages <= 3 ||
                  totalPages - 1 <= currentPage ? (
                    <button
                      key={startPage + index}
                      onClick={() => paginate(startPage + index)}
                      className={`px-3 py-1 rounded-md ${
                        currentPage === startPage + index
                          ? "bg-bluesens text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {startPage + index}
                    </button>
                  ) : (
                    <>
                      <span>...</span>
                      <button
                        key={totalPages}
                        onClick={() => paginate(totalPages)}
                        className={`px-3 py-1 rounded-md ${
                          currentPage === startPage + index
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200"
                        }`}
                      >
                        {totalPages}
                      </button>
                    </>
                  )
                )}

                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/arrow_forward.svg`}
                    alt="Next Page"
                    className="h-5 w-5"
                  />
                </button>

                <button
                  onClick={() => paginate(totalPages)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/skip_next.svg`}
                    alt="Last Page"
                    className="h-5 w-5"
                  />
                </button>
              </div>
            </div>
          )}
          {organizations && organizations.length > 0 && (
          <div>
            <label htmlFor="pageSize" className="mr-2">
              Elementos por Página:
            </label>
            <select
              id="pageSize"
              value={organizationsPerPage}
              onChange={(e) => {
                setOrganizationsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1); // Reset to page 1 when page size changes
              }}
              className="border rounded-md py-1 px-2"
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>)}
        </div>

        <Modal
          isOpen={showDeleteConfirmation}
          title="Confirmar Eliminación"
          showCloseButton={false}
        >
          <p className="mb-4">
            ¿Estás seguro de que quieres eliminar la organización{" "}
            <span className="font-bold text-red-500">
              {organizationToDelete?.name || "Desconocida"}
            </span>
            ?
          </p>
          <div className="flex justify-end">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={handleCompleteDeleting}
            >
              Eliminar
            </button>
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancelar
            </button>
          </div>
        </Modal>
      </div>

      {isCreating || isUpdating || isDeleting ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Success Modal */}
          <Modal
            title="Éxito"
            isOpen={showSuccessModal}
            onClose={() => setShowSuccessModal(false)}
            colorTitle="bg-green-300"
            size="w-fit"
            showCloseButton={false}
          >
            <div className="text-center">
              <p>{successMessage}</p>
            </div>
          </Modal>
          {/* Error Modal */}
          <Modal
            title="Error"
            isOpen={showErrorModal}
            onClose={() => setShowErrorModal(false)}
            colorTitle="bg-red-300"
            size="w-fit"
            showCloseButton={false}
          >
            <div className="text-center">
              <p>{errorMessage}</p>
            </div>
          </Modal>
        </>
      )}
    </Container>
  );
}

export default Organizations;