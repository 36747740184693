import React from 'react';
import ResizableDraggableCard from './ResizableDraggableCard';
import RegistersTableWidget from '../features/processes/components/widgets/RegistersTable/RegistersTable';
import HorizontalBarChartWidget from '../features/processes/components/widgets/HorizontalBarChart/HorizontalBarChartWidget';
import PieChartWidget from '../features/processes/components/widgets/PieChart/PieChartWidget';
import { useWidgets } from '../providers/WidgetContext';

const DashboardSpace = () => {
  
  const {
    containerRef,
    cards,
    handleDrag, 
    handleResize, 
    duplicateCard, 
    deleteCard} = useWidgets();
  return (
    <div
      ref={containerRef}
      className="relative w-full h-screen bg-gray-100 overflow-auto p-4"
    >
      {cards.map(card => (
        <ResizableDraggableCard
          key={card.id}
          id={card.id}
          x={card.x}
          y={card.y}
          width={card.width}
          height={card.height}
          title={card.title}
          containerWidth={containerRef.current?.offsetWidth || 0}
          containerHeight={containerRef.current?.offsetHeight || 0}
          onDrag={handleDrag}
          onResize={handleResize}
          onDuplicate={duplicateCard}
          onDelete={deleteCard}
        >
          {card.type === "processTable"&& (
            <RegistersTableWidget />
          )}
          {card.type === "processHorizontalBarChart" && (
            <HorizontalBarChartWidget id={card.id}/>
          )}
          {card.type === "processPieChart" && (
            <PieChartWidget id={card.id}/>
          )}
          
        </ResizableDraggableCard>
      ))}
    </div>
  );
};

export default DashboardSpace;
