import React from 'react'

function TableChartIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#020317"}) {
  return (
    <svg 
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}>
        <path d="M760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120ZM200-640h560v-120H200v120Zm100 80H200v360h100v-360Zm360 0v360h100v-360H660Zm-80 0H380v360h200v-360Z"/>
    </svg>
  )
}

export default TableChartIco