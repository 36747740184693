// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: Roboto, "Helvetica Neue", sans-serif; 
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,mBAAmB;EACnB,0BAA0B;EAC1B,gBAAgB;EAChB,iDAAiD;AACnD","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-weight: 400;\n  font-size: 0.875rem;\n  color: rgba(0, 0, 0, 0.87);\n  line-height: 1.5;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
