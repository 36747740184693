import React from 'react'
import Container from '../../../layouts/Container'
import ProcessesContainer from '../../../layouts/ProcessesContainer'
import { ProcessesProvider } from '../../../providers/ProcessesContext'

function Processes() {
  return (
    <Container>
      <ProcessesProvider>
        <ProcessesContainer />
      </ProcessesProvider>
    </Container>
    
  )
}

export default Processes