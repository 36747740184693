import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';

//icons
import TrashIco from './icons/TrashIco'
import EditIco from './icons/EditIco'
import SkipNextIco from './icons/SkipNextIco'
import SkipPreviousIco from './icons/SkipPreviousIco'
import ArrowForward from './icons/ArrowForward'
import ArrowBack from './icons/ArrowBack'
import Spinner from './Spinner'

import ComboBox from './ComboBox'
import { useAuth } from '../providers/AuthContext'
import { useAppContext } from '../providers/AppContext'
import { useNavigate } from 'react-router-dom'


function Pagination({total, currentPage, maxPaginationPages, onChange}) {
  const totalPages = Math.ceil(total/maxPaginationPages);
  function getPagination(c, m, g) {
    console.log(c,m,g)
    // Si el número máximo de números a mostrar es mayor o igual al total de páginas, mostramos todas las páginas
    if (g >= m) {
      return Array.from({ length: m }, (_, i) => i + 1);
    }
    let start, end;
    // Si la página actual está en el principio
    if (c <= Math.floor(g / 2)) {
      start = 1;
      end = g;
    } 
    // Si la página actual está cerca del final
    else if (c + Math.floor(g / 2) >= m) {
      start = m - g + 1;
      end = m;
    } 
    // Si la página actual está en el medio
    else {
      start = c - Math.floor(g / 2);
      end = c + Math.floor(g / 2);
    }
    // Generamos el arreglo de números de página
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  let arrayPages = getPagination(currentPage,totalPages , maxPaginationPages)

  return (
    <div className='flex gap-1'>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== 1 ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(1)}>
        <SkipPreviousIco fill={currentPage !==1 ? "gray" : undefined}/>
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== 1 ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(currentPage-1 <= 1 ? 1: currentPage-1)}>
        <ArrowBack height='16px' width='16px' fill={currentPage !==1 ? "gray" : undefined}/>
      </button>
      {arrayPages.map((element,index)=> (
        <button key={index}
          className={`mx-0.5 rounded-md min-w-6 h-6 ${ element === currentPage ? 'bg-bluesens text-white' : 'bg-white hover:bg-graysens'  }`} value={element} onClick={event => onChange(event.target.value)}
        >
          {element}
        </button>
      ))}
      
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== totalPages ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(currentPage+1 >= totalPages? totalPages: currentPage+1)}>
        <ArrowForward height='16px' width='16px' fill={currentPage !==totalPages ? "gray" : undefined}/>
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== totalPages ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(totalPages)}>
        <SkipNextIco fill={currentPage !==totalPages ? "gray" : undefined}/>
      </button>
    </div>
  )
}
const GenericTable = forwardRef(({ actions = [], fetcher , externalId}, ref) => {
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [registers, setRegisters] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsNumber, setRowsNumber] = useState(10);
  const {auth} = useAuth();
  const {addToast} = useAppContext();
  const navigate = useNavigate();

  const [fetchingData, setFetchingData] = useState(true);
  
  const refreshTable = () => {
    async function fetchData(){
      //page = 1, limit = 5, searchTerm = "", sortBy = JSON.stringify({ column: "createdAt", isAscending: true })
      let parameters = await fetcher(
        auth?.token,
        externalId,
        currentPage,
        rowsNumber,
        search
      );
      if (externalId){
        parameters = await fetcher(
          auth?.token,
          externalId,
          currentPage,
          rowsNumber,
          search,
        );
      }
      else{
        parameters = await fetcher(
          auth?.token,
          currentPage,
          rowsNumber,
        );
      }
      return parameters;
    }
    try {
      setFetchingData(true);
      fetchData().then(response => {
        console.log(console.log(response));
        setRegisters(response.records);
        setHeaders(response.headers);
        setTotal(response.totalRecords);
        setFetchingData(false);
      }).catch(error => {
        if (!error.response){
          addToast("Error inesperado, intente nuevamente",2)
        }
        else if (error.response.status === 419){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        else{
          addToast("Error en el servidor, intente nuevamente",2)
        }
      })
    } catch (error) {
      addToast("Error inesperado, intente nuevamente",2)
    }
  }
  const handleSearch = (searchValue) => {
    setSearch(searchValue)
  }
  useImperativeHandle(ref, () => ({
    refreshTable,
    handleSearch
  }));
  
  const handlePagination = (selectedPage) => {
    setCurrentPage(Number(selectedPage));
  }

  const handleRowSelection = (event) => {
    setRowsNumber(Number(event.currentTarget.value));
    setCurrentPage(1);
  }
   useEffect( () => {
    refreshTable();
   }, [rowsNumber , currentPage , search]);

  return (
    <div className="overflow-x-auto mb-5">
      <div className="min-w-full shadow-md  border">
        {/* Header */}
        {fetchingData && (
          <div
            className="grid text-gray-500 font-semibold p-3 border-b border-gray-300 shadow"
            style={{ gridTemplateColumns: `repeat(${headers.length}, minmax(0, 1fr))` }}
          >
          </div>
        )}
        {!fetchingData && (
          <div
            className="grid text-gray-500 font-semibold p-3 border-b border-gray-300 shadow"
            style={{ gridTemplateColumns: `repeat(${headers.length}, minmax(0, 1fr))` }}
          >
            {headers.map((headerItem, index) => (
              <div key={index} className="p-2 text-xs text-center">
                {headerItem}
              </div>
            ))}
          </div>
        )}
        {/* Body */}
        {fetchingData && (
          <div className='flex w-full mx-auto justify-center items-center my-5'>
            <Spinner />
          </div>
          
        )}
        {!fetchingData && registers.length > 0 && (
          <div>
            {registers.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className="grid px-3 py-1 hover:bg-graysens"
                style={{ gridTemplateColumns: `repeat(${headers.length}, minmax(0, 1fr))` }}
              >
                {headers.map((headerItem, colIndex) => (
                  <div key={colIndex} className="p-2 text-center">
                    {headerItem === 'ACCIONES' ? (
                      <div className='flex items-center justify-center gap-4 '>
                        {
                          row[headerItem].map((action, actionIndex) => {
                            if (action === "EDIT") {
                              return (
                              <div key={actionIndex} id={rowIndex} className='cursor-pointer' onClick={() => actions[action](registers[rowIndex])}>
                                <EditIco fill='bluesens'/>
                              </div>)
                            }
                            if (action === "DELETE") {
                              return (
                              <div key={actionIndex} id={rowIndex} className='cursor-pointer' onClick={() => actions[action](registers[rowIndex])}>
                                <TrashIco height="18px" fill='red'/>
                              </div>)
                            }
                          })
                        }
                      </div>
                      
                    ) : (
                      <div>
                        {
                          !row[headerItem] && typeof(row[headerItem]) === "object" && (
                            <>--</>
                          )
                        }
                        { typeof(row[headerItem]) !== "object" && (
                          <>
                          {row[headerItem] ?  row[headerItem] : "--"}
                          </>
                        )}
                        { typeof(row[headerItem]) === "object" && !!row[headerItem] && (
                          <span>
                            Ver
                          </span>
                        )}
                      </div>
                      
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        {registers.length === 0 && !fetchingData && (
          <div className='flex items-center justify-center text-gray-700  my-5'>
            <span>No se encontraron registros</span>
          </div>
        )}
        {/* Footer */}
        {!fetchingData && registers.length > 0 && (
          <section id="footer" className='w-100 shadow-md border-t items-center flex '>
            <div className='flex justify-between py-3 w-full'>
              <div className='mx-3 text-sm  text-gray-500'>{total} total</div>
              <div className='mx-9'>
                <ComboBox
                  value={rowsNumber}
                  label={"resultados por página"}
                  options={[5,10,15,20,25,30]}
                  onChange={handleRowSelection}
                />
              </div>
              <div className='mx-3'>
                <Pagination
                  total={total}
                  currentPage={currentPage}
                  maxPaginationPages={rowsNumber}
                  onChange={handlePagination}
                />
              </div>
            </div>
          </section>
        )}
        
      </div>
    </div>
  )
});

export default GenericTable