import React, { useEffect, useState } from 'react'
import InputForm from '../../../components/InputForm'
import SelectionForm from '../../../components/SelectionForm'
import ButtonForm from '../../../components/ButtonForm'
import { createParameter, updateParameter } from '../../../services/apiProcessParameteres'
import { useAppContext } from '../../../providers/AppContext'
import { useAuth } from '../../../providers/AuthContext'
import { useNavigate } from 'react-router-dom'
import SpinnerText from '../../../components/SpinnerText'

const datatypeOptions = {
  "numerico": "numeric",
  "fecha": "date",
  "lista": "dictarray"
}
function AddEditParameter({processId, parameter, toggle, refreshTable}) {
  const navigate = useNavigate();
  const {addToast} = useAppContext();
  const {auth} = useAuth();
  const [key, setKey] = useState(parameter?.Clave);
  const [alias, setAlias] = useState(parameter?.Alias);
  const [datatype, setDatatype] = useState(parameter ? parameter["Tipo de dato"]: undefined);
  const [inputs, setInputs] = useState({
    processId: processId,
    _id: parameter?._id,
    key : parameter?.Clave,
    alias: parameter?.Alias,
    datatype: parameter ? parameter["Tipo de dato"]: undefined
  })
  const [formError, setFormError] = useState({field: "", error: ""});
  const [updating, setUpdating] = useState(false);
 
  const handleAddParameter = ()=>{
    async function doAdd(){
      let parameterToSave;
      if (parameter){
        parameterToSave = await updateParameter(
          inputs,
        auth?.token);
      }else{
        parameterToSave = await createParameter(
          inputs,
        auth?.token);
      }
      return parameterToSave;
    }
    try{
      setFormError({field: "", error: ""})
      setUpdating(true);
      doAdd().then(response => {
        setUpdating(false);
        addToast(parameter ? 'Parámetro actualizado' : 'Parámetro agregado' , 0);
        toggle();
        refreshTable();
      }).catch(error => {
        setUpdating(false);
        if ([400].includes(error.response.status)){
          let errorData = error.response.data
          if (errorData && errorData.field && errorData.error){
            let tempError = {...formError}
            tempError.field =  errorData.field
            tempError.error =  errorData.error
            setFormError(tempError);
          }
        }
        if ([404].includes(error.response.status)){
          addToast("Parametro no encontrado",2)
        }
        if ([419].includes(error.response.status)){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        if ([500].includes(error.response.status)){
          addToast('Ocurrió un error al crear parámetro ' , 3);
          toggle();
        } 
      })
    }
    catch( error) {
      console.log(error);
      setUpdating(false);
      addToast('Ocurrió un error inesperado, intente nuevamente' , 3)
      
    }
  }

  useEffect( ()=> {
    let tempInputs = {... inputs}
    tempInputs.alias = alias
    tempInputs.key = key
    tempInputs.datatype = datatype
    setInputs(tempInputs)
  }, [key, alias, datatype])
  console.log(inputs)
  return (
    <div className='w-100 mx-auto flex flex-col'>
      <InputForm text={"Nombre de parámetro"} value={inputs.key} onChange={(event) => setKey(event.target.value)} error={ formError.field === "key" ? formError.error : ""}/>
      <InputForm text={"Alias"} value={inputs.alias} onChange={(event) => setAlias(event.target.value)}/>
      <SelectionForm 
        options={Object.keys(datatypeOptions)}
        text={"Tipo"} 
        id={"type"}
        value={inputs.datatype}
        onChange={(option) => {setDatatype(option)}}
        />
      <div className='mx-auto mt-16'>
        {updating && (
          <SpinnerText text={"Guardando..."}/>
        )}
        {!updating && (
          <ButtonForm text={"Guardar"} enabled={true} action={() => handleAddParameter()}/>
        )}
          
      </div>
      
    </div>
  )
}

export default AddEditParameter