import React from 'react'
import MenuIcon from '../../../../components/icons/MenuIcon'
import AddCircle from '../../../../components/icons/AddCircle'
import SyncIco from '../../../../components/icons/SyncIco'
import CropFreeIco from '../../../../components/icons/CropFreeIco'
import CameraIco from '../../../../components/icons/CameraIco'
import DashboardMenu from './DashboardMenu'
import DashboardDataRange from './DashboardDataRange'
import { useDashboards } from '../../../../providers/DashboardContext'


function HeadBar({toggleNewWidgetModal}) {
  const {dashboard} = useDashboards();
  return (
    <div className='bg-white shadow-md w-full px-4 min-h-[6%] flex items-center justify-between'>
      <div className='flex gap-1 basis-1/3'>
        <DashboardMenu />
        <span className=' my-auto'>{dashboard.name}</span>
      </div>
      <DashboardDataRange />
      <div className='flex gap-3 items-center basis-1/3 justify-end'>
        <CameraIco height='25px' width="25px" fill='bluesens' />
        <CropFreeIco height='25px' width="25px" fill='bluesens' />
        <SyncIco height='25px' width="25px" fill='bluesens'/>
        <button onClick={toggleNewWidgetModal}>
          <AddCircle height='35px' width="35px" fill='bluesens'/>
        </button>
        
      </div>
    </div>
  )
}

export default HeadBar