import React, { useEffect, useState } from 'react'
import InputForm from '../../../../components/InputForm'
import SelectionForm from '../../../../components/SelectionForm'
import CheckIco from '../../../../components/icons/CheckIco'
import SpinnerText from '../../../../components/SpinnerText'
import { getFormattedDates, translateDatePicker } from '../../../utils'
import { createDashboard, updateDashboard } from '../../../../services/apiDashboards'
import { useAppContext } from '../../../../providers/AppContext'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../providers/AuthContext'
import Spinner from '../../../../components/Spinner'

function DashboardForm({dashboard, toggle, refreshList}) {
  const {addToast} = useAppContext();
  const {auth} = useAuth();
  const navigate = useNavigate();
  const [dateOptions, setDateOptions] = useState(getFormattedDates(new Date()));
  const [dataRange, setDataRange] = useState('Hoy');
  const [formError, setFormError] = useState({field: "", error: ""});
  const [form, setForm] = useState(); 
  //banderas backend
  const [updating, setUpdating] = useState(false);
  const [fetching, setFeching ] = useState(true);

  const handleNameChange = (name) => {
    let tempForm = {...form}
    tempForm.name = name.trim();
    setForm({...tempForm});
  }
  const handleDateFormatChange = (dateFormat) => {
    let tempForm = {...form}
    tempForm.dateFormat = dateFormat;
    setForm({...tempForm});
  }
  const handleDataRangeChange = (selectedRange) => {
    setDataRange(translateDatePicker(selectedRange))
    //guardar form
    let tempForm = {...form}
    tempForm.dateRange = selectedRange
    setForm({...tempForm})
  }

  const handleAddDashboard = ()=>{
    async function doAdd(){
      let dashboardToSave;
      if (dashboard){
        dashboardToSave = await updateDashboard(
          form,
        auth?.token);
      }else{
        dashboardToSave = await createDashboard(
          form,
        auth?.token);
      }
      return dashboardToSave;
    }
    try{
      setFormError({field: "", error: ""})
      setUpdating(true);
      doAdd().then(response => {
        setUpdating(false);
        addToast(dashboard ? 'Dashboard actualizado' : 'Dashboard agregado' , 0);
        refreshList();
        toggle();
      }).catch(error => {
        setUpdating(false);
        if ([400].includes(error.response.status)){
          let errorData = error.response.data
          if (errorData && errorData.field && errorData.error){
            let tempError = {...formError}
            tempError.field =  errorData.field
            tempError.error =  errorData.error
            setFormError(tempError);
          }
          
        }
        if ([404].includes(error.response.status)){
          addToast("Dashboard no encontrado",2)
          toggle();
        }
        if ([419].includes(error.response.status)){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        if ([500].includes(error.response.status)){
          addToast('Ocurrió un error al crear dashboard ' , 3);
        } 
      })
    }
    catch( error) {
      console.log(error);
      setUpdating(false);
      addToast('Ocurrió un error inesperado, intente nuevamente' , 3);
    }
  }

  useEffect( () => {
    if (dashboard){
      setForm({
        _id: dashboard._id,
        name: dashboard.name,
        dateFormat: dashboard.dateFormat,
        dateRange: dashboard.dateRange,
      });
      setDataRange(translateDatePicker(dashboard.dateRange))
      setFeching(false);
    }else{
      setForm(
        {
          name: "",
          dateFormat: "mm/dd/yy - hh:mm",
          dateRange: {
            activeTab: 'customDates',
            selectedRange: 'today',
          },
          module: "process"
        }
      )
      setFeching(false);
    }
    console.log(dashboard)
  }, [dashboard])
  return (
    <div className='flex flex-col justify-between'>
      
      { !fetching && (
        <>
          <div className='flex flex-col mb-32'>
            <InputForm text={"Nombre"} value={form.name} onChange={event => handleNameChange(event.target.value)}/>
            {/*<DataRangePicker onChange={(event) => console.log(event)}/>*/}
            <SelectionForm 
              text="Formato de fecha" 
              options={Object.keys(dateOptions)} 
              mask={dateOptions} 
              value={form.dateFormat}
              onChange={event => handleDateFormatChange(event)} />
            <InputForm 
              type={"datarange"} 
              text={"Rango de tiempo predeterminado"} 
              value={dataRange} 
              AppendClassName='text-left' 
              onChange={(event) => handleDataRangeChange(event)}
              dateRangeOptions={form.dateRange} 
            />
          </div>
          <div className='w-full flex justify-end'>
            {updating && (
              <div>
                <SpinnerText text={"Guardando..."}/>
              </div>
              
            )}
            {!updating && (
              <button className='' onClick={() => handleAddDashboard()}>
                <CheckIco width='50px' height='50px'/>
              </button>
            )}
          </div>
        </>
      ) }
      { fetching && (
        <div className='flex flex-col mb-32'>
          <Spinner />
        </div>
       
      )}
      
    </div>
  )
}

export default DashboardForm