import React from 'react'

function ArrowDownwardIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#e8eaed"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox} 
      width={width}
      fill={fill}>
        <path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z"/>
    </svg>
  )
}

export default ArrowDownwardIco