import React from 'react'

export default function Card(props) {
  return (
    <div
      className={'flex w-full max-w-[90%] lg:max-w-[30%] md:max-w-[60%] h-auto overflow-hidden shadow-lg bg-white rounded-xl' }
    >
      {props.children}
    </div>
  )
}
