import React, { useEffect, useState } from 'react';

export default function Toaster({ message, type = 0, duration = 5000, onClose }) {
  const [isVisible, setIsVisible] = useState(false); // Comienza oculto

  useEffect(() => {
    // Activar la animación de entrada
    setIsVisible(true);

    const timer = setTimeout(() => {
      handleClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  const handleClose = () => {
    setIsVisible(false); // Inicia la animación de salida
    setTimeout(() => {
      onClose(); // Llama a onClose después de la animación de salida
    }, 300); // 300ms coincide con la duración de la animación
  };

  let bgColor;
  let borderColor;

  switch (type) {
    case 0:
      bgColor = 'bg-green-500';
      borderColor = 'border-green-700';
      break;
    case 1:
      bgColor = 'bg-blue-500';
      borderColor = 'border-blue-700';
      break;
    case 2:
      bgColor = 'bg-yellow-500';
      borderColor = 'border-yellow-700';
      break;
    case 3:
      bgColor = 'bg-red-500';
      borderColor = 'border-red-700';
      break;
    default:
      bgColor = 'bg-green-500';
      borderColor = 'border-green-700';
  }

  return (
    <div
      className={`fixed top-5 right-5 z-50 p-4 rounded-md shadow-lg border-l-4 ${bgColor} ${borderColor} text-white flex justify-between items-center transition-all duration-300 ease-in-out transform ${
        isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
      }`}
    >
      <span>{message}</span>
      <button
        onClick={handleClose}
        className="ml-4 bg-transparent text-white hover:text-gray-300"
      >
        ✕
      </button>
    </div>
  );
}
