import React, { useEffect, useRef, useState } from 'react'
import InputForm from '../../../components/InputForm'
import SelectionForm from '../../../components/SelectionForm'
import ButtonForm from '../../../components/ButtonForm'
import Button from '../../../components/Button'
import SettingsIco from '../../../components/icons/SettingsIco'
import SearchIco from '../../../components/icons/SearchIco'
import Spinner from '../../../components/Spinner'
import GenericTable from '../../../components/GenericTable'
import AddEditRegisters from './AddEditRegisters'
import Modal from '../../../components/Modal'
import EditTableSettings from './EditTableSettings'
import { deleteRegister, getRegisters } from '../../../services/apiProcessRegisters'
import { useProcesses } from '../../../providers/ProcessesContext'
import { getProcessById } from '../../../services/apiProcess'
import { useAuth } from '../../../providers/AuthContext'
import { useAppContext } from '../../../providers/AppContext'
import { useNavigate } from 'react-router-dom'
import { getAllParameters } from '../../../services/apiProcessParameteres'



function RegistersSection() {
  const tableRef = useRef();
  const {selectedOption} = useProcesses();
  const {auth} = useAuth();
  const {addToast} = useAppContext();
  const navigate = useNavigate();
  
  const [showSettingsForm, setShowSettingsForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showDeleteRegister, setShowDeleteRegister] = useState(false);
  const [registerToEdit, setRegisterToEdit] = useState(undefined);
  const [process, setProcess] = useState();
  const [parameters, setParameters] = useState([]);
  //filtro de busqueda
  const [field, setField] = useState("");
  const [text, setText] = useState("");

  //banderas backend 
  const [fetchingProcess, setFetchingProcess] = useState(true);
  const [fetchingParameters, setFetchingParameters] = useState(true);
  const [deletingRegister, setDeletingRegister] = useState(false);

  const handleRefresh = () => {
    if (tableRef.current) {
      tableRef.current.refreshTable();
      setRegisterToEdit(undefined)
      refreshProcessPage();
    }
  };
  const handleFieldChange = (value) => {
    let selectedParameter = parameters?.find(parameter => parameter.alias === value || parameter.key === value)
    setField(selectedParameter.key)
  }
  const handleTextChange = (value) => {
    setText(value)
  }
  const refreshProcessPage = () => {
    async function fetchProcess(){
      const process = await getProcessById(selectedOption, auth?.token);
      return process; 
    }
    try {
      setFetchingProcess(true);
      fetchProcess().then(response => {
        console.log(console.log(response))
        setProcess(response);
        setFetchingProcess(false);
      }).catch(error => {
        if (error.response.status === 404){
          addToast("Proceso no encontrado",3)
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
        }
        else{
          navigate('/logout')
        }
        
      })
    } catch (error) {
      console.log(error, "a", typeof(error));
      setFetchingProcess(false);
    }
  }

  const refreshParameters = () => {
    async function fetchParameters(){
      const parameters = await getAllParameters(selectedOption, auth?.token);
      return parameters;
    }
    try {
      setFetchingParameters(true);
      fetchParameters().then(response => {
        console.log(console.log(response))
        setParameters(response.map(parameter => {
          return {_id: parameter._id, key: parameter.key, alias: parameter.alias}
        }));
        setFetchingParameters(false);
      }).catch(error => {
        if (error.response.status === 404){
          addToast("Proceso no encontrado",3)
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
        }
        else if (error.response.status === 419){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        else{
          navigate('/logout')
        }
      })
    } catch (error) {
      console.log(error, "a", typeof(error));
      setFetchingParameters(false);
    }
  }

  const handleDeleteRegister = () => {
    
    async function doDelete(){
      const deleted = await deleteRegister({_id: registerToEdit._id}, auth?.token);
      return deleted;
    }
    try{
      setDeletingRegister(true);
      doDelete().then(response => {
        setShowDeleteRegister(false)
        setDeletingRegister(false);
        handleRefresh();
        addToast('Parámetro eliminado' , 0);    
      }).catch(error => {
        console.log(error, typeof(error));
        addToast('Ocurrió un error al eliminar el parámetro ' , 3)
        setDeletingRegister(false);
      })
    }
    catch( error) {
      console.log(error);
      addToast('Ocurrió un error inesperado, intente nuevamente' , 3)
      setDeletingRegister(false);
    }
  }

  const toggleSettingsModal = (event) => {
    setShowSettingsForm(prev => !prev);
  }
  const toggleRegisterModal = (event) => {
    setShowRegisterForm(prev=> !prev);
  }
  const toggleDeleteRegisterModal = (event) => {
    setShowDeleteRegister(prev=> !prev);
  }

  useEffect( () => {
    console.log("traer info del ", selectedOption);
    refreshProcessPage();
  }, [selectedOption]);

  useEffect( () => {
    refreshParameters()
  }, [selectedOption])

  useEffect( () => {
    if (tableRef.current) {
      tableRef.current.handleSearch({field, text});
    }
  }, [field, text])
  return (
    <div className='w-full h-full overflow-y-scroll bg-graysens'>
      {/* Modal nuevo registro */}
      <Modal
        isOpen={showRegisterForm}
        onClose={() => {
          setShowRegisterForm(false);
          setRegisterToEdit(null);
        }}
        customType={2}
        size='w-2/4'
        colorTitle='bg-bluesens'
        title={registerToEdit ? "Editar registro" : "Nuevo Registro"}
      >
        <AddEditRegisters  
          register={registerToEdit}
          toggleModal={toggleRegisterModal}
          refreshTable={handleRefresh}
        />
      </Modal>
      {/* Modal de configuracion de tabla */}
      <Modal
        isOpen={showSettingsForm}
        onClose={() => {
          setShowSettingsForm(false);
        }}
        customType={2}
        colorTitle='bg-bluesens'
        title={"Configurar tabla"}
      >
        <EditTableSettings toggleModal={toggleSettingsModal} refreshTable={handleRefresh}/>
      </Modal>
      {/* Modal confirmación eliminar registro*/}
      <Modal
        isOpen={showDeleteRegister}
        onClose={() => {
          setShowDeleteRegister(false);
          setRegisterToEdit(null);
        }}
        customType={2}
        colorTitle='bg-red-600 '
        title={"Eliminar registro"}
      >
        <p className="mb-4">
          ¿Está seguro de eliminar este registro?
        </p>
        <div className="flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => handleDeleteRegister()}
            disabled={deletingRegister}
          >
            {deletingRegister ? "Eliminando...": "Eliminar"}
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={() => setShowDeleteRegister(false)}
          >
            Cancelar
          </button>
        </div>
      </Modal>
      {!fetchingProcess && !fetchingParameters &&(
        <div className='w-[95%] mx-auto h-full bg-white'>
          <div className='px-10 w-full pt-5'>
            {parameters?.length > 0 && (
              <section id='filters' className='w-full flex items-center'>
                <div className='flex basis-2/4 gap-2' >
                  <div className='w-[70%]'>
                    <InputForm 
                      text={"Buscar por"} 
                      Icon={ () => {return (<SearchIco />)}}
                      enabled = {process?.tableSettings.length > 0 && field}
                      onChange={event => handleTextChange(event.target.value)}
                    />
                  </div>
                  <div className='w-[30%]'>
                    <SelectionForm 
                      text={"Campo"} 
                      options={process?.tableSettings.map(parameter => parameter.alias || parameter.key)} 
                      onChange={(event) => handleFieldChange(event)}
                      enabled = {process?.tableSettings.length > 0}
                    />
                  </div>
                </div>
                <div className='flex gap-2 -mt-5 basis-2/4 justify-end'>
                  <ButtonForm text={"Nuevo registro"} action={toggleRegisterModal} enabled={true}/>
                  <Button text={"Conf. tabla"} Icon={ () => {return (<SettingsIco fill='gray'/>)}} 
                  onClick={(event) => toggleSettingsModal()}  />
                </div>
              </section>
            )}
            {parameters?.length === 0 && (
              <div className="flex relative h-10 bg-yellow-200 w-full justify-center items-center transition-max-height duration-150 ease-in-out mb-5">
                <div className="absolute">
                  <span className="text-gray-600 font-semibold">
                    Debe crear parámetros en la sección de configuración antes de usar esta opción
                  </span>
                </div>
              </div>
            )}
            <section>
            <GenericTable
              actions={{
                "EDIT": (register) => {setRegisterToEdit(register); toggleRegisterModal();},
                "DELETE":  (register) => {setRegisterToEdit(register); toggleDeleteRegisterModal();}
              }}
              fetcher={getRegisters}
              externalId = {  process._id }
              ref={tableRef} 
            />
            </section>
          </div>
        </div>
      )}
      {fetchingProcess  && (
        <div className='w-full h-full flex items-center justify-center'>
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default RegistersSection