import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { getAllProcesses } from '../services/apiProcess';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from './AppContext';

const ProcessesContext = createContext();
export const useProcesses= () => useContext(ProcessesContext);

export const ProcessesProvider = ({ children }) => {
  const {addToast} = useAppContext();
  const [selectedOption, setSelectedOption] = useState("Dashboard");
  const navitage = useNavigate();
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const {auth} = useAuth();
  const [fetchingProcess, setFetchingProcess] = useState(true);
  const [menuItems, setMenuItems] = useState([
    { label: 'Dashboard', index: 0 },
  ]);
  
  const refreshMenu = () => {
    async function fetchProcesses() {
      const processsData = await getAllProcesses(auth?.token);
      //setOrganizations(organizationsData);
      console.log(processsData)
      return processsData;
    }
    try {
      fetchProcesses().then(response => {
        let menuTemp = [
          { id: "Dashboard", label: 'Dashboard', index: 0 },
        ]
        response.map((element, index) => 
          menuTemp.push({
            label: element.name,
            index: index+1,
            id: element._id,
            hasSubItems: true,
            subItems: ['Registros', 'Configuración'] 
          })
        );
        setMenuItems(menuTemp);
        setFetchingProcess(false);
      }).catch(error => 
        {
          if ([419].includes(error.response.status)){
            addToast("Sesión expirada",1)
            navitage('/logout')
          }
          if ([500].includes(error.response.status)){
            addToast("Ocurrio un error con el servidor, intente nuevamente",3)
          }
        }
      );
    } catch (error) {
      console.log(error)
      setFetchingProcess(false);
    }
  }

  const selectLastMenuItem = () =>{
    console.log(menuItems[menuItems.length-1])
    setSelectedOption("Dashboard")
    setSelectedSubOption("Configuración")
  }

  useEffect ( () => {
    refreshMenu();
  }, [auth])
  /*
  const menuItems = [
    { label: 'Dashboard', index: 0 },
    { label: 'Proceso 1', index: 1, hasSubItems: true, subItems: ['Registros', 'Configuración'] },
    { label: 'Proceso 2', index: 2, hasSubItems: true, subItems: ['Registros', 'Configuración'] },
  ];
  */
  return (
    <ProcessesContext.Provider value={{
      selectedOption,setSelectedOption,
      selectedSubOption, setSelectedSubOption,
      menuItems,
      fetchingProcess,
      refreshMenu,
      selectLastMenuItem
    }}>
      {children}
    </ProcessesContext.Provider>
  );
};
