import React from 'react'

export default function ButtonForm({text, action, enabled, Icon, type="form"}) {
  let style = ""
  if (type === "form"){
    style = enabled ? 
    "text-white font-bold bg-bluesens  focus:ring-4 focus:outline-none focus:ring-blue-300 rounded text-sm w-full sm:w-auto px-4 py-2 text-center" :
    "text-gray-500  bg-graysens  focus:ring-4 focus:outline-none focus:ring-blue-300 rounded text-sm w-full sm:w-auto px-4 py-2 text-center"
  }
  if ( type === "warning"){
    style = enabled ? 
    "text-white font-bold bg-red-500  focus:ring-4 focus:outline-none focus:ring-red-300 rounded text-sm w-full sm:w-auto px-4 py-2 text-center" :
    "text-gray-500  bg-red-200  focus:ring-4 focus:outline-none focus:ring-red-300 rounded text-sm w-full sm:w-auto px-4 py-2 text-center"
  }
  return (
    <button 
      type="submit"
      className={style}
      onClick={action}
      disabled={!enabled}
    >
      <div className='flex gap-1 justify-center items-center'>
        {Icon && (
          <Icon />
        )}
        <span className='opacity-85'>
          {text}
        </span>
      </div>
      
      
    </button>
  )
}
