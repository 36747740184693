import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

function DropdownMenu({ label, icon, options }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation(); // Get the current URL location

  // Determine if any option is active
  const isAnyOptionActive = options.some(option => location.pathname.startsWith(option.to));

  useEffect(() => {
    // Close the dropdown when navigating to a different page (outside the dropdown)
    if (!isAnyOptionActive) {
      setIsDropdownOpen(false);
    }
  }, [location, isAnyOptionActive]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative">
      {/* Main NavLink (without to prop) */}
      <div
        className={`flex gap-1 items-center px-6 py-3 cursor-pointer border-b-[2.5px] ${
          isAnyOptionActive ? "border-bluesens text-gray-700" : "border-transparent text-gray-700"
        }`}
        onClick={toggleDropdown}
      >
        {icon && <img className="w-4 h-4" alt={label} src={icon} />}
        {label}
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute top-full left-0 bg-white shadow-md  w-48 z-10">
          {options.map((option) => (
            <NavLink
              key={option.to}
              to={option.to}
              className={`block px-4 py-2 hover:bg-gray-100 ${
                location.pathname.startsWith(option.to) ? "bg-blue-100 text-blue-500" : "" // Highlight active option
              }`}
              onClick={toggleDropdown}
            >
              {option.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
