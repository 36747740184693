import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/processes'; // URL de procesos

// Get all organizations
export async function getAllProcesses(token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get organization by ID
export async function getProcessById(procId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${procId}`,
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data; 
  } catch (error) {
    throw error;
  }
}

// Create a new organization
export async function createProcess(newProcess, token) {
  try {
    const response = await axios.post(API_BASE_URL, newProcess, 
      {
        headers: {Authorization: "Bearer "+ token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Update an existing organization
export async function updateProcess(updatedProcess, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedProcess._id}`, updatedProcess,
      {
        headers: {Authorization: "Bearer "+ token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Delete an organization
export async function deleteProcess(deletedProcess, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedProcess._id}`,
      {
        headers: {Authorization: "Bearer "+ token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
