import React from "react";

const TextAreaInput = ({
  label,
  id,
  value,
  onChange,
  placeholder = "",
  error = "",
  required = false,
  enabled = true,
  isTextArea = false,
  AppendClassName = "",
  type = "text",
}) => {
  const InputElement = isTextArea ? "textarea" : "input";
  return (
    <div
      className={"relative z-0 w-full group" + (error ? " animate-shake" : "")}
    >
      <InputElement
        className={
          "block py-3.5 px-4 w-full " +
          (error
            ? "text-red_error border-2 border-red_error rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-red_error peer"
            : "text-gray-900 border-2 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-900 peer") +
          " " +
          AppendClassName
        }
        type={type}
        name={id}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        disabled={!enabled}
        rows={isTextArea ? 4 : undefined} // Optional: rows for textarea
      />
      <label
        htmlFor={id}
        className={
          "peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] peer-focus:left-4 " +
          (error
            ? "text-red_error peer-focus:text-red_error"
            : "text-gray-500 peer-focus:text-blue-900") +
          " peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-6 " + 
          " bg-white px-1 "
        }
      >
        {label}
      </label>
      {/* Conditionally render error message */}
      {error && (
        <div className="relative pb-1">
          <span className="px-1 absolute text-red_error">{error}</span>
        </div>
      )}
    </div>
  );
};

export default TextAreaInput;
