import React from "react";

function PermissionRow({ permission, onEdit, onDelete }) {
  return (
    <tr className="border-b">
      <td className="px-4 py-2">{permission.name}</td>
      <td className="px-4 py-2">{permission.description || "-"}</td>
      <td className="px-4 py-2">{permission.module || ""}</td>
      <td className="px-4 py-2">{new Date(permission.createdAt).toISOString().replace('T', ' ').slice(0, -5)}</td>
      <td className="px-4 py-2">
        <button onClick={() => onEdit(permission)} className="mr-2">
          <img
            src={`${process.env.PUBLIC_URL}/svg/editIco.svg`}
            alt="Editar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
        <button onClick={() => onDelete(permission)}>
          <img
            src={`${process.env.PUBLIC_URL}/svg/deleteIco.svg`}
            alt="Eliminar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
      </td>
    </tr>
  );
}

export default PermissionRow;
