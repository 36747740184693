import React from 'react';
import RoleRow from './RoleRow';

function RoleTable({ roles, onEdit, onDelete, onSort, sortBy }) { 
  sortBy = JSON.parse(sortBy);
  const handleHeaderClick = (column) => {
      if (onSort) {
        onSort(column); // Call the parent component's sorting function
      }
    };

    return (
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th 
              onClick={() => handleHeaderClick('name')} 
              className={`cursor-pointer px-4 py-2 text-left w-1/6`}
            >
              Nombre {(sortBy.column === 'name' && (sortBy.isAscending ? '↑' : '↓'))|| '↑↓'}
            </th>
            <th 
              onClick={() => handleHeaderClick('description')} 
              className={`cursor-pointer px-4 py-2 text-left w-2/6` }
            >
              Descripción {(sortBy.column === 'description' && (sortBy.isAscending ? '↑' : '↓')) || '↑↓'}
            </th>
            <th 
              onClick={() => handleHeaderClick('organizationIds')} 
              className={`cursor-pointer px-4 py-2 text-left`}
            >
              Organizaciones {(sortBy.column === 'organizationIds' && (sortBy.isAscending ? '↑' : '↓')) || '↑↓'}
            </th>
            <th className="px-4 py-2 text-left">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {roles?.length > 0 ? (
            roles.map((role) => (
              <RoleRow
                key={role._id}
                role={role}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center py-4">
                No hay roles existentes
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
}

export default RoleTable;