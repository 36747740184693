import React, { useState, useRef, useEffect } from "react";
import ConfirmButton from "../../../components/ConfirmButton";
import TextInput from "../../../components/TextInput";

function OrganizationForm({ onSubmit, organization }) {
  const [name, setName] = useState(organization?.name || "");
  const [description, setDescription] = useState(
    organization?.description || ""
  );
  const [color, setColor] = useState(organization?.color || "#001317");
  const [logo, setLogo] = useState(organization?.logo || null);

  const fileInputRef = useRef(null);

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Resize
          const maxWidth = 800;
          const maxHeight = 600;
          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            const ratio = Math.min(maxWidth / width, maxHeight / height);
            width = width * ratio;
            height = height * ratio;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              // Quality compression
              canvas.toDataURL("image/jpeg", 0.8);
              const compressedImage = canvas.toDataURL("image/jpeg", 0.8);
              setLogo(compressedImage);
            },
            "image/jpeg",
            0.8
          );
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      console.error("El archivo debe ser PNG o JPEG");
      setLogo(null);
    }
  };

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(name.trim() !== ""); // The form is valid if the name is not empty
  }, [name]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let info = { logo, name, description, color };
    onSubmit({ ...organization, ...info });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-y-2"
    >
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="logo"
        >
          Logo:
        </label>
        <div
          className="border-2 border-dashed border-gray-400 p-4 rounded-lg cursor-pointer"
          onClick={handleImageUpload}
        >
          {logo ? (
            <img
              src={logo}
              alt="Logo de la organización"
              className="w-full h-auto max-h-48 object-contain"
            />
          ) : (
            <p className="text-gray-500">
              Haz clic para subir una imagen (PNG o JPEG)
            </p>
          )}
        </div>
        <input
          type="file"
          accept="image/png, image/jpeg"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
        />
      </div>

      <div className="mb-4 flex items-center">
        <label
          className="block text-gray-700 text-sm font-bold mb-2 mr-4"
          htmlFor="color"
        >
          Color:
        </label>
        <input
          className="shadow appearance-none border rounded w-10 h-10"
          id="color"
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </div>

      <TextInput
        label="Nombre de la organización"
        id="nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <TextInput
        label="Descripción"
        id="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        isTextArea={true}
      />

      <ConfirmButton isConfirmed={isFormValid} />
    </form>
  );
}

export default OrganizationForm;
