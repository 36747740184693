import axios from "axios";


const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + "/users"; // URL de organizationes

// Get all users (considering pagination, filtering and sorting)
export async function getUsers(
  page = 1,
  limit = 10,
  searchTerm = "",
  sortBy = JSON.stringify({ column: "createdAt", isAscending: true }),
  searchTerm2 = "",
  token
) {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        currentPage: page,
        documentsPerPage: limit,
        sortBy,
        filterObj: JSON.stringify({
          general: searchTerm,
          organizationId: searchTerm2
        }),
      },
      headers:
      {Authorization: "Bearer "+token}
    });
    return response.data;
  } catch (error) {
    throw new Error("Error getting users: " + error.message);
  }
}

// Get a user by username
export async function getUserByUsername(username, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/username/${username}`,
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return undefined;
    } else {
      throw new Error("Error fetching user by username: " + error.message);
    }
  }
}
// Get a user by email
export async function getUserByEmail(email, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/email/${email}`,
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return undefined;
    } else {
      throw new Error("Error fetching user by username: " + error.message);
    }
  }
}

// Create a new user
export async function createUser(newUser, token) {
  try {
    const response = await axios.post(API_BASE_URL, newUser,
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error creating user: " + error.message);
  }
}

// Update an existing user
export async function updateUser(updatedUser, token) {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/${updatedUser._id}`,
      updatedUser,
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error updating user: " + error.message);
  }
}

// Delete an user
export async function deleteUser(deletedUser, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedUser._id}`,
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error deleting user: " + error.message);
  }
}
