import React from 'react'

function ArrowForward({height="24px", width="24px", viewBox="0 -960 960 960", fill="#e8eaed"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox} 
      width={width}
      fill={fill}
    >
      <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/>
    </svg>
  )
}

export default ArrowForward