import React from 'react'

function FunctionsIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#e8eaed"}) {
  return (
    <svg 
    height={height}
    viewBox={viewBox} 
    width={width}
    fill={fill}>
      <path d="M240-160v-80l260-240-260-240v-80h480v120H431l215 200-215 200h289v120H240Z"/>
    </svg>
  )
}

export default FunctionsIco