import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/dashboards'; // URL de variables

// Get all variables (considering pagination, filtering and sorting)
export async function getDashboards(token, module = "telemetry", page = 1, limit = 10, searchTerm = "", sortBy = JSON.stringify({ column: "createdAt", isAscending: true }) ) {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy,
        module
      },
      headers:
        {Authorization: "Bearer "+token}
      ,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get all variables (considering pagination, filtering and sorting)
export async function getAllDashboards(token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}


// Get variable by ID
export async function getDashboardById(dashboardId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${dashboardId}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data; 
  } catch (error) {
    throw error;
  }
}

// Get a variable by its name
export async function getDashboardByName(dashboardName, token) {
  try {
    const response = await axios.get(API_BASE_URL+'/getByName/' + dashboardName,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return (response.data)?response.data:undefined;
  } catch (error) {
    throw error;
  }
}

// Create a new dashboard
export async function createDashboard(newDashboard, token) {
  try {
    const response = await axios.post(API_BASE_URL, newDashboard, 
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Update an existing Dashboard
export async function updateDashboard(updatedDashboard, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedDashboard._id}`, updatedDashboard,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Delete an Dashboard
export async function deleteDashboard(deletedDashboard, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedDashboard._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
