import React, { useEffect, useState } from 'react';

const SelectableList = ({ items, multiSelect = false , setItems}) => {
  const [selectedItems, setSelectedItems] = useState(multiSelect ? [] : null);

  useEffect( () => {
    setItems(selectedItems);
  }, [selectedItems])

  const handleSelectItem = (item) => {
    if (multiSelect) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.includes(item)
          ? prevSelectedItems.filter((i) => i !== item) // Deseleccionar si ya está seleccionado
          : [...prevSelectedItems, item] // Agregar a seleccionados si no está seleccionado
      );
    } else {
      setSelectedItems(item === selectedItems ? null : item); // Alternar la selección si es único
    }
  };

  return (
    <ul className="flex flex-col  selectable-list p-0">
      {items.map((item) => (
        <li key={item} className="list-none">
          <label className="flex items-center cursor-pointer py-2 text-lg">
            <input
              type="checkbox"
              checked={multiSelect ? selectedItems.includes(item) : selectedItems === item}
              onChange={() => handleSelectItem(item)}
              className={`mr-3 w-5 h-4 ${multiSelect ? (selectedItems.includes(item) ? 'bg-yellow-500' : 'bg-white') : (selectedItems === item ? 'bg-yellow-500' : 'bg-white')}`}
              style={{ accentColor: 'aliceblue' }} // Asegura que el check esté en amarillo
            />
            <span className={`${multiSelect ? (selectedItems.includes(item) ? 'text-black' : 'text-gray-300') : (selectedItems === item ? 'text-yellow-500' : 'text-black')}`}>
              {item}
            </span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default SelectableList;
