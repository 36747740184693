import React, { createContext, useState, useContext } from 'react';

const DashboardContext = createContext();
export const useDashboards= () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const [module, setModule] = useState("telemetry");
  const [dashboard, setDashboard] =  useState({
    name: "REPORTE DE OPERACIONES Y VENTAS",
    id: "AB6263232323123123213"
  });

  
  return (
    <DashboardContext.Provider value={
      {
        module, setModule,
        dashboard, setDashboard,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};
