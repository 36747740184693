import React from 'react'

export default function ArrowDropDown({height="24px", width="24px", viewBox="0 -960 960 960", fill="#020317"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}
    >
      <path d="M480-360 280-560h400L480-360Z"/>
    </svg>
  )
}
