import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/roles'; // URL de roles

// Get all roles (considering pagination, filtering and sorting)
export async function getRoles(page = 1, limit = 10, searchTerm = "", sortBy, token) {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy
      },
        headers: {Authorization: "Bearer "+token}
      ,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error getting roles: ' + error.message);
  }
}

// Get all roles (considering pagination, filtering and sorting)
export async function getAllRoles(token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {headers:
        {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error getting roles: ' + error.message);
  }
}

// Get a role by its name
export async function getRoleByName(roleName, token) {
  try {
    const response = await axios.get(API_BASE_URL+'/name/', {
      params: {
        name: roleName 
      },
      headers: {Authorization: "Bearer "+token}
    });
    // Ensure only one role is returned or handle the case of multiple roles with the same name
    if (response.data && response.data.length > 0) {
      return response.data[0]; // Return the first matching role
    } else {
      return null; // No role found
    }
  } catch (error) {
    throw new Error('Error getting role by name: ' + error.message);
  }
}

// Create a new role
export async function createRole(newRole, token) {
  try {
    const response = await axios.post(API_BASE_URL, newRole,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating role: ' + error.message);
  }
}

// Update an existing role
export async function updateRole(updatedRole, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedRole._id}`, updatedRole,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating role: ' + error.message);
  }
}

// Delete an role
export async function deleteRole(deletedRole, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedRole._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting role: ' + error.message);
  }
}
