import React, { useState, useEffect, useCallback } from "react";
import Container from "../../../layouts/Container";
import Modal from "../../../components/Modal";
import SearchBar from "../../../components/SearchBar";
import PermissionForm from "../components/PermissionForm";
import PermissionTable from "../components/PermissionTable";
import ButtonForm from "../../../components/ButtonForm";
import Spinner from "../../../components/Spinner";
import SelectInput from "../../../components/SelectInput";

import {
  getPermissions,
  createPermission,
  updatePermission,
  deletePermission,
} from "../../../services/apiPermissions";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthContext";

function Permissions() {
  const navigate = useNavigate();
  const {auth} = useAuth();
  const [permissions, setPermissions] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [permissionToEdit, setPermissionToEdit] = useState(null);
  const [permissionToDelete, setPermissionToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const pageSizes = [2, 5, 10, 20];
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [sortBy, setSortBy] = useState(
    JSON.stringify({ column: "createdAt", isAscending: true })
  );

  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");

  const refreshElements = useCallback(async () => {
    setIsLoading(true);
    try {
      getPermissions(
        currentPage,
        elementsPerPage,
        searchTerm || selectedModule,
        sortBy,
        auth?.token
      ).then(data => {
        setPermissions(data.records);
        setTotalElements(data.totalRecords);
        setTotalPages(data.totalPages);
      });
      
    } catch (error) {
      console.error("Error fetching permissions:", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, elementsPerPage, searchTerm, sortBy, selectedModule]);

  useEffect(() => {
    refreshElements();
  }, [refreshElements]);

  const handleCreatePermission = async (newPermission) => {
    setIsCreating(true);
    try {
      createPermission(newPermission, auth?.token).then(response => {
        setShowForm(false);
        refreshElements();
        setSuccessMessage("Permiso creado exitosamente!");
        setShowSuccessModal(true);
      }).catch( error => 
        navigate('/logout')
      );
    } catch (error) {
      console.error("Error al crear permiso:", error);
      console.log(error.status);
      setErrorMessage("Error al crear permiso. Por favor, inténtalo de nuevo.");
      setShowErrorModal(true);
      //Check is the Permission Name exists (TODO?)
    } finally {
      setIsCreating(false);
    }
  };

  const handleEditPermission = (permission) => {
    setIsUpdating(true);
    setPermissionToEdit(permission);
    setShowForm(true);
    setIsUpdating(false);
  };

  const handleCompleteEditing = async (updatedPermission) => {
    setIsUpdating(true);
    try {
      updatePermission(updatedPermission, auth?.token).then(response => {
        setPermissionToEdit(null);
        setShowForm(false);
        refreshElements();
        setSuccessMessage("Permiso editado exitosamente!");
        setShowSuccessModal(true);
      }).catch(error => 
        navigate('/logout')
      );
    } catch (error) {
      console.error("Error al actualizar permiso:", error);
      setErrorMessage(
        "Error al editar permiso. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeletePermission = (permission) => {
    setPermissionToDelete(permission);
    setShowDeleteConfirmation(true);
  };

  const handleCompleteDeleting = async () => {
    setIsDeleting(true);
    try {
      if (permissionToDelete.roleIds.length > 0) {
        throw new Error();
      }
      deletePermission(permissionToDelete, auth?.token).then(response => {
        setPermissionToDelete(null);
        setShowDeleteConfirmation(false);
        refreshElements();
        setSuccessMessage("Permiso eliminado exitosamente!");
        setShowSuccessModal(true);
      }).catch(error => 
        navigate('/logout')
      );
    } catch (error) {
      console.error("Error al eliminar permiso:", error);
      if (permissionToDelete.roleIds.length > 0) {
        setErrorMessage(
          "No puede eliminar este permiso porque esta asignado a un rol"
        );
      } else {
        setErrorMessage(
          "Error al eliminar permiso. Por favor, inténtalo de nuevo."
        );
      }
      setShowErrorModal(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const startPage =
    currentPage === totalPages
      ? Math.max(1, currentPage - 2)
      : Math.max(1, currentPage - 1);
  const endPage = Math.min(totalPages, startPage + 2);

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
        setSuccessMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessModal]);

  useEffect(() => {
    if (showErrorModal) {
      const timer = setTimeout(() => {
        setShowErrorModal(false);
        setErrorMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showErrorModal]);

  const handleHeaderClick = (column) => {
    const currentSort = JSON.parse(sortBy);
    const newIsAscending =
      currentSort.column === column ? !currentSort.isAscending : true;
    setSortBy(JSON.stringify({ column, isAscending: newIsAscending }));
    setCurrentPage(1);
  };

  useEffect(() => {
    fetch("/api/modules")
      .then((response) => response.json())
      .then((data) => setModules(data));
  }, []);

  const moduleOptions = [
    "Dashboard",
    "Usuarios",
    "Dispositivos",
    "Organizaciones",
  ];

  return (
    <Container>
      <div className="w-11/12 mx-auto p-4 my-2">
        <div className="flex items-center justify-between my-2">
          <SelectInput
            label="Módulo"
            id="moduleFilter"
            options={moduleOptions || modules}
            value={selectedModule}
            onChange={(e) => setSelectedModule(e.target.value)}
            placeholder={`Todos (${moduleOptions.length || modules.length})`}
          />
          <SearchBar
            type="search"
            text="Filtrar por Permiso"
            id="search"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <ButtonForm
            text="Crear Permiso"
            enabled={true}
            action={() => setShowForm(true)}
          />
        </div>

        <Modal
          isOpen={showForm}
          onClose={() => {
            setShowForm(false);
            setPermissionToEdit(null);
          }}
          title={
            permissionToEdit
              ? `Editar Permiso " ${permissionToEdit?.name} "`
              : "Crear Permiso"
          }
        >
          {isUpdating ? (
            <div className="flex justify-center items-center h-screen">
              <Spinner />
            </div>
          ) : (
            <PermissionForm
              onSubmit={
                permissionToEdit
                  ? handleCompleteEditing
                  : handleCreatePermission
              }
              permission={permissionToEdit}
            />
          )}
        </Modal>

        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <Spinner />
          </div>
        ) : (
          <PermissionTable
            permissions={permissions}
            onEdit={handleEditPermission}
            onDelete={handleDeletePermission}
            onSort={handleHeaderClick} // Pass the sorting function to the table
            sortBy={sortBy} // Pass current sort state to the table
          />
        )}

        <div className="mt-4 flex justify-between items-center">
          <div className="flex justify-between items-center">
            <p className="mr-2">Total de Permisos:</p>
            <span
              className={`text-3xl font-bold transition-all duration-500 text-bluesens`}
            >
              {totalElements}
            </span>
          </div>
          {permissions.length > 0 && (
            <div className="flex flex-col justify-center gap-y-2 items-center">
              <span>Navegación entre Páginas</span>
              <div className="flex gap-x-1 items-center border-2 rounded p-2">
                <button
                  onClick={() => paginate(1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/skip_previous.svg`}
                    alt="First Page"
                    className="h-5 w-5"
                  />
                </button>

                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/arrow_back.svg`}
                    alt="Previous Page"
                    className="h-5 w-5"
                  />
                </button>

                {Array.from({ length: endPage - startPage + 1 }, (_, index) =>
                  index + 1 <= endPage - startPage ||
                  totalPages <= 3 ||
                  totalPages - 1 <= currentPage ? (
                    <button
                      key={startPage + index}
                      onClick={() => paginate(startPage + index)}
                      className={`px-3 py-1 rounded-md ${
                        currentPage === startPage + index
                          ? "bg-bluesens text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {startPage + index}
                    </button>
                  ) : (
                    <>
                      <span>...</span>
                      <button
                        key={totalPages}
                        onClick={() => paginate(totalPages)}
                        className={`px-3 py-1 rounded-md ${
                          currentPage === startPage + index
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200"
                        }`}
                      >
                        {totalPages}
                      </button>
                    </>
                  )
                )}

                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/arrow_forward.svg`}
                    alt="Next Page"
                    className="h-5 w-5"
                  />
                </button>

                <button
                  onClick={() => paginate(totalPages)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-md border-2 border-bluesens border-solid disabled:opacity-50 disabled:border-none`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/svg/skip_next.svg`}
                    alt="Last Page"
                    className="h-5 w-5"
                  />
                </button>
              </div>
            </div>
          )}
          {permissions.length > 0 && (
            <div>
              <label htmlFor="pageSize" className="mr-2">
                Elementos por Página:
              </label>
              <select
                id="pageSize"
                value={elementsPerPage}
                onChange={(e) => {
                  setElementsPerPage(parseInt(e.target.value, 10));
                  setCurrentPage(1); // Reset to page 1 when page size changes
                }}
                className="border rounded-md py-1 px-2"
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <Modal
          isOpen={showDeleteConfirmation}
          title="Confirmar Eliminación"
          showCloseButton={false}
        >
          <p className="mb-4">
            ¿Estás seguro de que quieres eliminar el permiso{" "}
            <span className="font-bold text-red-500">
              {permissionToDelete?.name || "Desconocida"}
            </span>
            ?
          </p>
          <div className="flex justify-end">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={handleCompleteDeleting}
            >
              Eliminar
            </button>
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancelar
            </button>
          </div>
        </Modal>
      </div>

      {isCreating || isUpdating || isDeleting ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Success Modal */}
          <Modal
            title="Éxito"
            isOpen={showSuccessModal}
            onClose={() => setShowSuccessModal(false)}
            colorTitle="bg-green-300"
            size="w-fit"
            showCloseButton={false}
          >
            <div className="text-center">
              <p>{successMessage}</p>
            </div>
          </Modal>
          {/* Error Modal */}
          <Modal
            title="Error"
            isOpen={showErrorModal}
            onClose={() => setShowErrorModal(false)}
            colorTitle="bg-red-300"
            size="w-fit"
            showCloseButton={false}
          >
            <div className="text-center">
              <p>{errorMessage}</p>
            </div>
          </Modal>
        </>
      )}
    </Container>
  );
}

export default Permissions;