import React from 'react'
import Container from '../../../layouts/Container'
import BreadcrumbBar from '../../../components/BreadcrumbBar'
function Dashboards() {
  return (
    <Container>
      <BreadcrumbBar />
      <div>Dashboards</div>
    </Container>
    
  )
}

export default Dashboards