import React from 'react'

function SkipPreviousIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#e8eaed"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={viewBox} 
      width={width}
      fill={fill}
    >
      <path d="M220-240v-480h80v480h-80Zm520 0L380-480l360-240v480Z"/>
    </svg>
  )
}

export default SkipPreviousIco