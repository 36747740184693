import React, { useEffect } from 'react'
import "../../../styles/login.css"
//components
import LogoCard from '../components/LogoCard'
import LoginForm from '../components/LoginForm'
import { useAuth } from '../../../providers/AuthContext'
import { useNavigate } from 'react-router-dom'
export default function Login() {
  const navigation = useNavigate();
  const {isAuth} = useAuth();

  useEffect( () => {
    isAuth() ? navigation("/") : navigation("/login");
  }, [isAuth,navigation]);

  return (
    <div
      className='height-100vh signup4-wrap ps'
      style={{
        backgroundSize: 'cover',
        flexFlow: 'wrap',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center',
        alignItems: 'center',
        height: '100vh' // Asegúrate de que el div ocupe toda la altura de la pantalla
      }}
    >
      <LogoCard>
        <LoginForm />
      </LogoCard>
    </div>
  )
}
