import React from 'react';
let index = ["Datos", "Tableros"];

export default function BreadcrumbBar() {
  return (
    <div className="relative w-full px-4 mt-2">
      <ul className="block m-0 p-0 overflow-hidden">
        {index.map((element) => (
          <li key={element} className={"inline-block float-left " + (element !== "Datos" ? " before:content-['/'] before:px-2" : "")}>
            <button className="min-w-16 text-sm px-5 leading-9 rounded-[4px] overflow-visible hover:bg-graysens">
              <span className="mat-button-wrapper">{element}</span>
              <div className="mat-button-ripple mat-ripple"></div>
              <div className="mat-button-focus-overlay"></div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
