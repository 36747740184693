import React, { createContext, useState, useContext, useRef } from 'react';
const WidgetContext = createContext();
export const useWidgets= () => useContext(WidgetContext);

export const WidgetProvider = ({ children }) => {
  const containerRef = useRef(null); 
  
  const [cards, setCards] = useState([
    /* { id: 1, x: 0, y: 0, width: 200, height: 200, title: "Widget 1" },
    { id: 2, x: 220, y: 0, width: 200, height: 200, title: "Widget 2" } */
  ]);


  let dashboardList = [
    {
      name: "REPORTE DE OPERACIONES Y VENTAS",
      id: "AB6263232323123123213"
    },
    {
      name: "REPORTE DE EXTRUSION",
      id: "6FA26B3232323123123213"
    },
    {
      name: "REPORTE DE SELLADO EXOTERMICO",
      id: "9FC26B3232323123123213"
    },
    
  ]

  const [dashboard, setDashboard] =  useState(dashboardList[0]);

  const isOverlapping = (card1, card2) => {
    return (
      card1.x < card2.x + card2.width &&
      card1.x + card1.width > card2.x &&
      card1.y < card2.y + card2.height &&
      card1.y + card1.height > card2.y
    );
  };

  const resolveCollision = (movingCard, otherCards) => {
    let newX = movingCard.x;
    let newY = movingCard.y;
    let spaceFound = false;

    while (!spaceFound) {
      spaceFound = true;

      for (let card of otherCards) {
        if (isOverlapping({ ...movingCard, x: newX, y: newY }, card)) {
          spaceFound = false;
          newX += 220; // Move to the right
          if (newX + movingCard.width > containerRef.current.offsetWidth) {
            newX = 0;
            newY += movingCard.height + 20; // Move to the next row
          }
          break;
        }
      }
    }

    return { newX, newY };
  };

  const handleMove = (id, newX, newY, newWidth, newHeight) => {
    setCards(prevCards => {
      let movingCard = prevCards.find(card => card.id === id);
      let updatedCards = prevCards.map(card => {
        if (card.id === id) {
          const adjustedX = newX !== undefined ? Math.max(newX, 0) : card.x;
          const adjustedY = newY !== undefined ? Math.max(newY, 0) : card.y;

          return {
            ...card,
            x: adjustedX,
            y: adjustedY,
            width: newWidth !== undefined ? newWidth : card.width,
            height: newHeight !== undefined ? newHeight : card.height
          };
        }
        return card;
      });

      updatedCards.forEach(card => {
        if (card.id !== id && isOverlapping(movingCard, card)) {
          const { newX: nextX, newY: nextY } = resolveCollision(card, updatedCards.filter(c => c.id !== card.id));
          card.x = nextX;
          card.y = nextY;
        }
      });

      return updatedCards;
    });
  };

  const handleDrag = (id, newX, newY) => {
    handleMove(id, newX, newY);
  };

  const handleResize = (id, newWidth, newHeight) => {
    handleMove(id, undefined, undefined, newWidth, newHeight);
  };

  const addCard = (type) => {
    setCards(prevCards => {
      const lastCard = prevCards[prevCards.length - 1];
      let newCardX = lastCard ? lastCard.x + 220: 0;
      let newCardY = lastCard ? lastCard.y: 0;

      let spaceFound = false;
      while (!spaceFound) {
        spaceFound = true;
        for (let card of prevCards) {
          if (isOverlapping({ x: newCardX, y: newCardY, width: 200, height: 200 }, card)) {
            spaceFound = false;
            newCardX += 220;
            if (newCardX + 200 > containerRef.current.offsetWidth) {
              newCardX = 0;
              newCardY += 220;
            }
            break;
          }
        }
      }

      return [
        ...prevCards,
        {
          id: prevCards.length + 1,
          x: newCardX,
          y: newCardY,
          width: 200,
          height: 200,
          title: `Widget ${prevCards.length + 1}`,
          type: type
        }
      ];
    });
  };

  const duplicateCard = (id) => {
    setCards(prevCards => {
      const cardToDuplicate = prevCards.find(card => card.id === id);
      if (!cardToDuplicate) return prevCards;

      let newCardX = cardToDuplicate.x + 220;
      let newCardY = cardToDuplicate.y;

      let spaceFound = false;
      while (!spaceFound) {
        spaceFound = true;
        for (let card of prevCards) {
          if (isOverlapping({ x: newCardX, y: newCardY, width: cardToDuplicate.width, height: cardToDuplicate.height }, card)) {
            spaceFound = false;
            newCardX += 220;
            if (newCardX + cardToDuplicate.width > containerRef.current.offsetWidth) {
              newCardX = 0;
              newCardY += 220;
            }
            break;
          }
        }
      }

      return [
        ...prevCards,
        {
          id: prevCards.length + 1,
          x: newCardX,
          y: newCardY,
          width: cardToDuplicate.width,
          height: cardToDuplicate.height,
          title: `Widget ${prevCards.length + 1}`,
          type: cardToDuplicate.type
        }
      ];
    });
  };

  const deleteCard = (id) => {
    setCards(prevCards => prevCards.filter(card => card.id !== id));
  };

  return (
    <WidgetContext.Provider value={
      {
        containerRef,
        cards, setCards,
        addCard,
        handleDrag,
        handleResize,
        duplicateCard,
        deleteCard,
        dashboard, setDashboard,
        dashboardList
      }}>
      {children}
    </WidgetContext.Provider>
  );
};
