import React from "react";
import PermissionRow from "./PermissionRow";

function PermissionTable({ permissions, onEdit, onDelete, onSort, sortBy }) {
  sortBy = JSON.parse(sortBy);
  const handleHeaderClick = (column) => {
    if (onSort) {
      onSort(column);
    }
  };

  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th
            onClick={() => handleHeaderClick("name")}
            className={`cursor-pointer px-4 py-2 text-left w-1/6`}
          >
            Permiso{" "}
            {(sortBy.column === "name" && (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("description")}
            className={`cursor-pointer px-4 py-2 text-left w-2/6`}
          >
            Descripción{" "}
            {(sortBy.column === "description" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("module")}
            className={`cursor-pointer px-4 py-2 text-left`}
          >
            Módulo{" "}
            {(sortBy.column === "module" && (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("createdAt")}
            className={`cursor-pointer px-4 py-2 text-left`}
          >
            Fecha de creación{" "}
            {(sortBy.column === "createdAt" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th className="px-4 py-2 text-left">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {permissions.length > 0 ? (
          permissions.map((permission) => (
            <PermissionRow
              key={permission._id}
              permission={permission}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))
        ) : (
          <tr>
            <td colSpan="6" className="text-center py-4">
              No hay permisos existentes
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default PermissionTable;
