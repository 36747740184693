import React, { useState, useEffect } from "react";
import ConfirmButton from "../../../components/ConfirmButton";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";

function PermissionForm({ onSubmit, permission }) {
  const moduleOptions = ["Dashboard", "Usuarios", "Dispositivos", "Organizaciones"];

  const [name, setName] = useState(permission?.name || "");
  const [description, setDescription] = useState(permission?.description || "");
  const [module, setModule] = useState(permission?.module || "");

  const [isFormValid, setIsFormValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setIsFormValid(name !== "" && module !== ""); // The form is valid if the name is not empty
  }, [name, module]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name.trim() === "" || module === "") {
      setShowErrors(true);
      return;
    }
    let info = { name, description, module };
    onSubmit({ ...permission, ...info });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-y-2"
    >
      <SelectInput
        label="Módulo"
        id="module"
        options={moduleOptions}
        value={module}
        onChange={(e) => setModule(e.target.value)}
        error={module === "" ? "Por favor, seleccione un módulo" : ""}
        showErrors={showErrors}
      />

      <TextInput
        label="Nombre del Permiso"
        id="nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <TextInput
        label="Descripción del Permiso"
        id="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        isTextArea={true}
      />

      <ConfirmButton isConfirmed={isFormValid} />
    </form>
  );
}

export default PermissionForm;
