import React, { useState } from 'react';
import InputForm from '../../../components/InputForm';
import ButtonForm from '../../../components/ButtonForm';
import Spinner from '../../../components/Spinner';
import axios from 'axios';
import { useAuth } from '../../../providers/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function LoginForm() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const doLogin = () => {
    let formTemplate = {};

    // Validación de campos
    if (user === "") {
      formTemplate.userError = "Usuario requerido";
    }
    if (password === "") {
      formTemplate.passError = "Contraseña requerida";
    }

    if (formTemplate.userError || formTemplate.passError) {
      setFormState({ ...formTemplate });
      return;
    }

    // Restablecer errores y activar el estado de carga
    setFormState({});
    setLoading(true);

    // Realizar la solicitud al backend
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
      username: user,
      password: password
    })
      .then(response => {
        setLoading(false);

        // Verificación de la estructura de la respuesta
        if (response && response.data && response.data.token) {
          login(response.data.token);
          setFormState({});
          navigate("/dashboard");
        } else {
          console.error("Respuesta inesperada del servidor:", response);
          setFormState({ formError: "Ocurrió un error al iniciar sesión. Inténtalo nuevamente." });
        }
      })
      .catch(error => {
        setLoading(false);

        // Manejo de error si la respuesta contiene data
        if (error.response && error.response.data && error.response.data.message) {
          console.error("Error en la solicitud:", error.response.data.message);
          formTemplate.formError = error.response.data.message;
        } else {
          console.error("Error de conexión:", error);
          formTemplate.formError = "Error de conexión. Por favor, revisa tu red e intenta nuevamente.";
        }
        setFormState({ ...formTemplate });
      });
  };

  return (
    <div className="w-full mx-auto p-5 lg:p-11">
      {!loading && (
        <>
          <InputForm
            type="text" id="username" text="Nombre de usuario"
            onChange={(event) => setUser(event.target.value)}
            enabled={!loading}
          />
          <InputForm
            type="password" id="password" text="Contraseña"
            onChange={(event) => setPassword(event.target.value)}
            enabled={!loading}
          />
          
          {formState.formError && (
            <div className='relative'>
              <span className='absolute -top-4 text-red-600'>{formState.formError}</span>
            </div>
          )}
          <div className='flex flex-col pt-3 gap-3 md:pt-0 md:gap-0 md:flex-row md:justify-between items-center'>
            <a href='/recovery'>¿Olvidó la contraseña?</a>
            <ButtonForm
              text={"Iniciar Sesión"}
              enabled={user !== "" && password !== ""}
              action={doLogin}
            />
          </div>
          <div className='flex justify-end pt-[35px]'>
            <select>
              <option> Español</option>
            </select>
          </div>
        </>
      )}

      {loading && (
        <>
          <div className='relative w-full '>
            <div className='relative'>
              <Spinner />
            </div>
          </div>
          <div className='mx-auto flex justify-center w-full mt-5'>
            <span className='text-md'>Iniciando sesión...</span>
          </div>
        </>
      )}
    </div>
  );
}
