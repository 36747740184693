import React, { useState } from 'react';
import DateRangePicker from './DataRangePicker';

export default function InputForm({ type, text, id, error = "", required = false, value = null, onChange = () => { }, enabled = true, AppendClassName = "", dateRangeOptions,  Icon }) {
  const [inputType, setInputType] = useState(type);
  const [isDateRangeOpen, setDateRangeOpen] = useState(false); // Estado para manejar la apertura del rango de fechas

  const togglePasswordVisibility = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const handleDateRangeClick = () => {
    // Alternar el estado del selector de rango de fechas
    setDateRangeOpen((prev) => !prev);
  };

  const handleDateRangeChange = (event) => {
    // Lógica para manejar el cambio en el rango de fechas
    onChange(event); // Ejecuta el onChange recibido
    setDateRangeOpen(false); // Cierra el selector de rango de fechas
  };

  const visibilityIcon = inputType === 'password'
    ? `${process.env.PUBLIC_URL}/svg/visibility_off.svg`
    : `${process.env.PUBLIC_URL}/svg/visibility.svg`;

  return (
    <div className={"relative z-0 w-full mb-5 group" + (error ? " animate-shake" : "")}>
      {type === 'datarange' ? (
        <button
          type="button"
          onClick={handleDateRangeClick}
          className={
            (error ?
              "block py-3.5 px-4 w-full text-red_error bg-transparent border-2 border-red_error rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-red_error peer" :
              "block py-3.5 px-4 w-full text-gray-900 bg-transparent border-2 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-900 peer") +
            " " + AppendClassName
          }
          disabled={!enabled}
        >
          {value} {/* Muestra el texto del botón */}
        </button>
      ) : (
        <input
          type={inputType}
          name={id}
          id={id}
          defaultValue={value}
          className={
            (error ?
              "block py-3.5 px-4 w-full text-red_error bg-transparent border-2 border-red_error rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-red_error peer" :
              "block py-3.5 px-4 w-full text-gray-900 bg-transparent border-2 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-900 peer") +
            " " + AppendClassName
          }
          placeholder=" "
          required={required}
          onChange={onChange} // Se ejecuta onChange al cambiar el valor
          disabled={!enabled}
        />
      )}
      <label
        htmlFor={id}
        className={
          error ?
            "peer-focus:font-medium absolute text-red_error duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] peer-focus:left-4 peer-focus:text-red_error bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6" :
            "peer-focus:font-medium absolute text-gray-500 duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] peer-focus:left-4 peer-focus:text-blue-900 bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        }
      >
        {text}
      </label>
      {Icon && type !== "password" && (
        <div className="absolute right-4 top-4 text-gray-600">
          <Icon />
        </div>
      )}
      {type === 'password' && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-4 top-4 text-gray-600"
        >
          <img
            src={visibilityIcon}
            alt="Toggle visibility"
            className="w-6 h-6"
          />
        </button>
      )}
      {error && (
        <div className='relative pb-1'>
          <span className='px-1 absolute text-red_error'>
            {error}
          </span>
        </div>
      )}

      {/* Desplegable para el selector de rango de fechas */}
      {isDateRangeOpen && (
        <div className="absolute z-50 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg">
          <DateRangePicker 
            onChange={handleDateRangeChange} 
            closeWindow={() => setDateRangeOpen(false)}
            options = {dateRangeOptions} 
          />
        </div>
      )}
    </div>
  );
}
