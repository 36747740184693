import React from 'react'
import "../../../styles/login.css"
import Card from '../../../components/Card'
import RecoveryForm from '../components/RecoveryForm'
export default function Recovery() {
  return (
    <div
      className='height-100vh signup4-wrap ps'
      style={{
        backgroundSize: 'cover',
        flexFlow: 'wrap',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center',
        alignItems: 'center',
        height: '100vh' // Asegúrate de que el div ocupe toda la altura de la pantalla
      }}
    >
      <Card>
        <RecoveryForm>
        </RecoveryForm>
      </Card>
    </div>
  )
}
