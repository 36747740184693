import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/processRegisters'; // URL de organizationes

// Get all organizations (considering pagination, filtering and sorting)
export async function getRegisters(token, processId, page = 1, limit = 5, searchTerm = "", sortBy = JSON.stringify({ column: "createdAt", isAscending: true })) {
  console.log(processId)
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        processId,
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy,
      },
      headers:
        {Authorization: "Bearer "+token}
      ,
    });
    return response.data;
  } catch (error) {
    throw error
  }
}

// Get all organizations
export async function getAllRegisters(processId, token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {
        params: {
          processId,
        },
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get organization by ID
export async function getRegisterById(paramId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${paramId}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data; 
  } catch (error) {
    throw error;
  }
}

// Create a new organization
export async function createRegister(newRegister, token) {
  try {
    const response = await axios.post(API_BASE_URL, newRegister,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Update an existing organization
export async function updateRegister(updatedRegister, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedRegister._id}`, updatedRegister,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Delete an organization
export async function deleteRegister(deletedRegister, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedRegister._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
