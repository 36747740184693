import React from 'react';

const ConfirmButton = ({ isConfirmed, onConfirm }) => {
    return (
        <div className="flex justify-end"> {/* Use flexbox for easy alignment */}
            <button
                className="rounded-full p-0 focus:outline-none focus:shadow-outline"
                onClick={isConfirmed ? onConfirm : null}
                disabled={!isConfirmed}
                type="submit"
            >
                <img
                    src={isConfirmed ? `${process.env.PUBLIC_URL}/svg/checkEnabledIco.svg` : `${process.env.PUBLIC_URL}/svg/checkDisabledIco.svg`}
                    alt="Confirm"
                    className="h-14 w-14 inline-block"
                />
            </button>
        </div>
    );
};

export default ConfirmButton;
