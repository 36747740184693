import React from 'react';

const Spinner = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-14 h-14 border-4 border-bluesens border-solid border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
}

export default Spinner;