import React, { useState, useEffect } from 'react';
//import "../../../styles/login.css"

export default function LogoCard(props) {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 100); // Simular retardo para la transición
  }, []);
  
  return (
    <div
      className={`flex flex-col md:flex-row w-full max-w-[95%] md:max-w-[60%] h-auto md:h-[42%] overflow-hidden shadow-lg bg-white rounded-xl transform transition-all duration-500 ease-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div
        className='flex w-full md:w-[70%] items-center content-center bg-graysens border-r-slate-200 border-r-2 '
      >
        <div
          className='p-[35px] mx-auto align-center content-center '
        >
          <img 
            alt='logo'
            src={`${process.env.PUBLIC_URL}/images/logos/sens.png`}
            width={250}
          />
        </div>
      </div>
      <div
        className='flex items-center align-center mx-auto w-full' 
      >
        {props.children}
      </div>
    </div>
  )
}
