import React, { useEffect, useState } from 'react';

// Función para generar colores pastel en función del índice
const generateColor = (index, count) => {
  const hue = (index / count) * 360; // Hue en base al índice
  return `hsl(${hue}, 70%, 80%)`; // Color pastel
};

const lightenColor = (color) => {
  // Convertir color HSL a RGB
  const hsl = color.match(/hsl\((\d+), (\d+)%, (\d+)%\)/);
  const h = parseInt(hsl[1], 10);
  const s = parseInt(hsl[2], 10);
  const l = parseInt(hsl[3], 10);

  // Aumentar la luminosidad para el efecto hover
  const newL = Math.min(l + 10, 90); // Evita que el color se vuelva demasiado claro
  return `hsl(${h}, ${s}%, ${newL}%)`;
};

const HorizontalBarChartWidget = ({ id }) => {
  const data = [
    { label: 'AAAA', value: 50 },
    { label: 'B', value: 75 },
    { label: 'C', value: 100 },
    { label: 'D', value: 25 },
    { label: 'E', value: 60 }
  ];

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [tooltip, setTooltip] = useState({ visible: false, top: 0, left: 0, label: '', value: '', color: '' });
  const [hoveredIndex, setHoveredIndex] = useState(null); // Index del elemento hover

  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById('chart-container' + id);
      if (container) {
        setContainerSize({
          width: container.clientWidth,
          height: container.clientHeight,
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [id]);

  const maxValue = Math.max(...data.map(d => d.value));
  const barHeight = containerSize.height / data.length - 8; // Calcula la altura de cada barra

  const handleMouseEnter = (event, label, value, index) => {
    const chartContainerRect = document.getElementById('chart-container' + id).getBoundingClientRect();

    const mouseXRelativeToChartContainer = event.clientX - chartContainerRect.left;
    const mouseYRelativeToChartContainer = event.clientY - chartContainerRect.top;
    const offset = 10;
    const baseColor = generateColor(index, data.length);

    setTooltip({
      visible: true,
      top: mouseYRelativeToChartContainer + offset,
      left: mouseXRelativeToChartContainer + offset,
      label,
      value,
      color: baseColor // Añade el color de la barra al tooltip
    });
    setHoveredIndex(index); // Establece el índice del elemento hover
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, top: 0, left: 0, label: '', value: '', color: '' });
    setHoveredIndex(null); // Restablece el índice del elemento hover
  };

  return (
    <div
      id={'chart-container' + id}
      className="relative flex flex-col  items-center w-full h-full p-4"
    >
      <div className='mb-10'>
        <span className=' font-bold p-2 text-gray-600'>A vs B</span>
      </div>
      {/* Tooltip */}
      {tooltip.visible && (
        <div
          className="absolute bg-gray-800 text-white text-sm rounded px-2 py-1 z-20 transition-opacity duration-300 mx-auto text-center"
          style={{ top: tooltip.top, left: tooltip.left, transform: 'translateX(-50%)' }}
        >
          <div className="flex items-center space-x-2">
            <div
              className="w-2.5 h-2.5 rounded-full"
              style={{ backgroundColor: tooltip.color }}
            ></div>
            <div>
              <div>{tooltip.label}</div>
              <div>{tooltip.value}</div>
            </div>
          </div>
        </div>
      )}

      <div className=" border-x-2 border-y-2 w-full py-5 relative grid gap-1" style={{ gridTemplateColumns: 'max-content 1fr min-content', gridAutoRows: `${barHeight}px` }}>
        {data.map((item, index) => {
          const baseColor = generateColor(index, data.length);
          const hoverColor = lightenColor(baseColor);

          return (
            <React.Fragment key={item.label}>
              
              {/* Etiqueta ajustable y alineada a la derecha */}
              <div className="text-right text-gray-700 px-2">{item.label}</div>

              {/* Barra */}
              <div
                className="relative transition-colors duration-300"
                style={{
                  backgroundColor: hoveredIndex === index ? hoverColor : baseColor,
                  width: `${(item.value / maxValue) * 100}%`,
                  height: '100%',
                }}
                onMouseEnter={(e) => handleMouseEnter(e, item.label, item.value, index)}
                onMouseLeave={handleMouseLeave}
              >
                {/* Espacio para el valor */}
                <span className="absolute right-[-30px] pl-2 pt-1 text-gray-700">
                  {item.value}
                </span>
              </div>

              {/* Espacio para el valor */}
              <div className="text-gray-700 px-2 mr-8"></div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default HorizontalBarChartWidget;
