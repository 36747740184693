export function elapsedTime(lastUpdate) {
    const now = new Date();
    const elapsedTimeMs = now - new Date(lastUpdate);

    const seconds = Math.floor(elapsedTimeMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    let elapsedTimeString = "";

    if (years > 0) {
        elapsedTimeString += `${years} año${years > 1 ? "s" : ""} `;
        if (months % 12 > 0) {
            elapsedTimeString += `y ${months % 12} mes${months % 12 > 1 ? "es" : ""}`;
        }
    } else if (months > 0) {
        elapsedTimeString += `${months} mes${months > 1 ? "es" : ""} `;
        if (days % 30 > 0) {
            elapsedTimeString += `y ${days % 30} día${days % 30 > 1 ? "s" : ""}`;
        }
    } else if (weeks > 0) {
        elapsedTimeString += `${weeks} semana${weeks > 1 ? "s" : ""} `;
        if (days % 7 > 0) {
            elapsedTimeString += `y ${days % 7} día${days % 7 > 1 ? "s" : ""}`;
        }
    } else if (days > 0) {
        elapsedTimeString += `${days} día${days > 1 ? "s" : ""} `;
        if (hours % 24 > 0) {
            elapsedTimeString += `y ${hours % 24} hora${hours % 24 > 1 ? "s" : ""}`;
        }
    } else if (hours > 0) {
        elapsedTimeString += `${hours} hora${hours > 1 ? "s" : ""} `;
        if (minutes % 60 > 0) {
            elapsedTimeString += `y ${minutes % 60} minuto${minutes % 60 > 1 ? "s" : ""
                }`;
        }
    } else if (minutes > 0) {
        elapsedTimeString += `${minutes} minuto${minutes > 1 ? "s" : ""} `;
    } else {
        elapsedTimeString = "pocos segundos";
    }

    return elapsedTimeString;
}

export function formatDate(stringDate) {
    return new Date(stringDate)
    .toISOString()
    .replace("T", " ")
    .slice(0, -5)
}
// Función para construir el objeto con todas las fechas formateadas
export function getFormattedDates(date) {
  const dateFormats = {
    "mm/dd/yy - hh:mm": (date) => {
        return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${String(date.getFullYear()).slice(2)} - ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    },
    "yyyy-mm-dd hh:mm:ss": (date) => {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    },
    "dd-mm-yyyy hh:mm:ss": (date) => {
        return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    },
    "mm-dd-yyyy hh:mm:ss": (date) => {
        return `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}-${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    },
    "Mon. dd yyyy hh:mm:ss": (date) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${months[date.getMonth()]}. ${String(date.getDate()).padStart(2, '0')} ${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    }
  };

  const formattedDates = {};

  for (const format in dateFormats) {
    formattedDates[format] = dateFormats[format](date);
  }

  return formattedDates;
}

export function translateDatePicker (selectedRange){
  if (selectedRange.activeTab === 'customDates'){
    let displayRange = 
    selectedRange.selectedRange === 'lastHour' ? 'Última Hora' :
    selectedRange.selectedRange === 'today' ? 'Hoy' :
    selectedRange.selectedRange === 'yesterday' ? 'Ayer' :
    selectedRange.selectedRange === 'last24Hours' ? 'Últimas 24 Horas' :
    selectedRange.selectedRange === 'thisWeek' ? 'Esta Semana' :
    selectedRange.selectedRange === 'lastWeek' ? 'Semana Pasada' :
    selectedRange.selectedRange === 'last7Days' ? 'Últimos 7 Días' :
    selectedRange.selectedRange === 'thisMonth' ? 'Este Mes' :
    selectedRange.selectedRange === 'lastMonth' ? 'Mes Pasado' :
    selectedRange.selectedRange === 'last30Days' ? 'Últimos 30 Días' :
      `${new Date(selectedRange.startDate).toLocaleDateString()} - ${new Date(selectedRange.endDate).toLocaleDateString()}`
    return displayRange
  }
  if (selectedRange.activeTab === 'customRange'){
    let displayUnits = selectedRange.customUnit === 'minutes' ? 'minutos' :
    selectedRange.customUnit === 'hours' ? 'horas' :
    selectedRange.customUnit === 'days' ? 'días' :
                'meses'
    return `Ultimos ${selectedRange.customLast} ${displayUnits}`
  }
  return ""
}