import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/processParameters'; // URL de organizationes

// Get all organizations (considering pagination, filtering and sorting)
export async function getParameters(token, processId, page = 1, limit = 5, searchTerm = "", sortBy = JSON.stringify({ column: "createdAt", isAscending: true })) {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        processId,
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy,
      },
      headers:
        {Authorization: "Bearer "+token}
      ,
    });
    return response.data;
  } catch (error) {
    throw error
  }
}

// Get all organizations
export async function getAllParameters(processId, token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {
        params: {
          processId,
        },
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get organization by ID
export async function getParameterById(paramId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${paramId}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data; 
  } catch (error) {
    throw error;
  }
}

// Create a new organization
export async function createParameter(newParameter, token) {
  try {
    const response = await axios.post(API_BASE_URL, newParameter,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Update an existing organization
export async function updateParameter(updatedParameter, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedParameter._id}`, updatedParameter,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Delete an organization
export async function deleteParameter(deletedParameter, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedParameter._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
