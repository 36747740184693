import React, { useEffect, useState, useRef } from 'react'
import InputForm from '../../../components/InputForm'
import ButtonForm from '../../../components/ButtonForm'
import GenericTable from '../../../components/GenericTable'
import AddEditParameter from './AddEditParameter'
import Modal from '../../../components/Modal'
import Spinner from '../../../components/Spinner'
import SpinnerText from '../../../components/SpinnerText'

//context
import { useProcesses } from '../../../providers/ProcessesContext'
import { useAuth } from '../../../providers/AuthContext'
//api
import { deleteProcess, getProcessById, updateProcess } from '../../../services/apiProcess'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../../providers/AppContext'
import { deleteParameter, getParameters } from '../../../services/apiProcessParameteres'


function ConfigSection() {
  const tableRef = useRef();
  const navigate = useNavigate();
  const {selectedOption , refreshMenu, selectLastMenuItem} = useProcesses();
  const {auth} = useAuth();
  const {addToast} = useAppContext()

  const [showForm, setShowForm] = useState(false);
  const [showConfForm, setShowConfForm] = useState(false);
  const [parameterToEdit, setParameterToEdit] = useState(undefined);
  const [showDeleteConf,setShowDeleteConf] = useState(false);

  const [process, setProcess] = useState();
  
  //banderas backend 
  const [fetchingProcess, setFetchingProcess] = useState(true);
  const [updatingProcess, setUpdatingProcess] = useState(false);
  const [deletingProcess, setDeletingProcess] = useState(false);
  const [deletingParameter, setDeletingParameter] = useState(false);

  const handleRefresh = () => {
    if (tableRef.current) {
      tableRef.current.refreshTable();
      setParameterToEdit(undefined)
    }
  };

  const handleUpdateProcess = () => {
    async function doUpdate(){
      const newProcess = await updateProcess({_id: process._id, name: process.name}, auth?.token);
      return newProcess;
    }
    try{
      setUpdatingProcess(true);
      doUpdate().then(response => {
        refreshMenu();
        setUpdatingProcess(false)
        addToast('Cambios guardados' , 0)
      }).catch(error => {
        console.log(error, typeof(error));
        addToast('Ocurrió un error al guardar' , 3)
        setUpdatingProcess(false)
      })
    }
    catch( error) {
      console.log(error);
      addToast('Ocurrió un error inesperado, intente nuevamente' , 3)
      setUpdatingProcess(false)
    }
    
  }

  const handleChangeName = (event) =>{
    let tempProcess = {...process}
    tempProcess.name = event.target.value
    setProcess(tempProcess);
  }

  const handleDeleteProcess = () => {
    
    async function doDelete(){
      const deleted = await deleteProcess({_id: process._id, name: process.name}, auth?.token);
      return deleted;
    }
    try{
      setShowDeleteConf(false);
      setDeletingProcess(true);
      doDelete().then(response => {
        refreshMenu();
        selectLastMenuItem();
        setDeletingProcess(false);
        addToast('Proceso eliminado' , 0);    
      }).catch(error => {
        console.log(error, typeof(error));
        addToast('Ocurrió un error al eliminar el proceso ' , 3)
        setDeletingProcess(false);
      })
    }
    catch( error) {
      console.log(error);
      addToast('Ocurrió un error inesperado, intente nuevamente' , 3)
      setDeletingProcess(false);
    }
  }

  const handleDeleteParameter = () => {
    
    async function doDelete(){
      const deleted = await deleteParameter({_id: parameterToEdit._id}, auth?.token);
      return deleted;
    }
    try{
      setDeletingParameter(true);
      doDelete().then(response => {
        setShowConfForm(false)
        setDeletingParameter(false);
        handleRefresh();
        addToast('Parámetro eliminado' , 0);    
      }).catch(error => {
        console.log(error, typeof(error));
        addToast('Ocurrió un error al eliminar el parámetro ' , 3)
        setDeletingParameter(false);
      })
    }
    catch( error) {
      console.log(error);
      addToast('Ocurrió un error inesperado, intente nuevamente' , 3)
      setDeletingParameter(false);
    }
  }

  const toggleEditModal = (event) => {
    setShowForm(prev => !prev);
  }
  const toggleConfirmationModal = (event) => {
    setShowConfForm(true);
  }
  const toggleDeleteProcessModal = (event) => {
    setShowDeleteConf(true);
  }
  const refreshProcessPage = () => {
    async function fetchProcess(){
      const process = await getProcessById(selectedOption, auth?.token);
      return process;
    }
    try {
      setFetchingProcess(true);
      fetchProcess().then(response => {
        console.log(console.log(response))
        setProcess(response);
        setFetchingProcess(false);
      }).catch(error => {
        if (error.response.status === 404){
          addToast("Proceso no encontrado",3)
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
        }
        else{
          navigate('/logout')
        }
        
      })
    } catch (error) {
      console.log(error, "a", typeof(error));
      setFetchingProcess(false);
    }
  }

  
  useEffect( () => {
    console.log("traer info del ", selectedOption);
    refreshProcessPage();
  }, [selectedOption]);
  
  
  return (
    <div className='w-full h-screen overflow-y-scroll bg-graysens'>
      {/* Modal confirmación eliminar proceso*/}
      <Modal
        isOpen={showDeleteConf}
        onClose={() => {
          setShowDeleteConf(false);
        }}
        customType={2}
        colorTitle='bg-red-600 '
        title={
          process
            ? `Eliminar proceso " ${process?.name} "`
            : "Eliminar proceso"
        }
      >
        <p className="mb-4">
          ¿Está seguro de eliminar este proceso?
        </p>
        <div className="flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => handleDeleteProcess()}
          >
            Eliminar
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={() => setShowDeleteConf(false)}
          >
            Cancelar
          </button>
        </div>
      </Modal>
      {/* Modal de edicion */}
      <Modal
        isOpen={showForm}
        onClose={() => {
          setShowForm(false);
          setParameterToEdit(null);
        }}
        customType={2}
        colorTitle='bg-bluesens'
        title={
          parameterToEdit
            ? `Editar parámetro " ${parameterToEdit?.Clave} "`
            : "Crear parámetro"
        }
      >
        <AddEditParameter
          processId={process?._id}
          parameter={parameterToEdit}
          toggle = {toggleEditModal}
          refreshTable = {handleRefresh}
        />
      </Modal>
      {/* Modal confirmación eliminar registro*/}
      <Modal
        isOpen={showConfForm}
        onClose={() => {
          setShowConfForm(false);
          setParameterToEdit(null);
        }}
        customType={2}
        colorTitle='bg-red-600 '
        title={
          parameterToEdit
            ? `Eliminar parametro " ${parameterToEdit?.Clave} "`
            : "Eliminar parametro"
        }
      >
        <p className="mb-4">
          ¿Está seguro de eliminar este parámetro?
        </p>
        <div className="flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => handleDeleteParameter()}
            disabled={deletingParameter}
          >
            {deletingParameter ? "Eliminando...": "Eliminar"}
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={() => setShowConfForm(false)}
          >
            Cancelar
          </button>
        </div>
      </Modal>

      {!fetchingProcess && (
        <div className='w-[95%] mx-auto min-h-screen bg-white'>
          <div className='px-10 w-full pt-5'>
            <section id='proccess-name' className='border-b mb-1'>
              <div className='flex items-center w-100 justify-between'>
                <span className='text-xl '>Datos del proceso</span>
                <ButtonForm 
                  text={deletingProcess ? "Eliminando ..." : "Eliminar proceso" } 
                  enabled={!deletingProcess} 
                  type='warning'
                  action={() => toggleDeleteProcessModal()}
                />
              </div>
              <div className='flex flex-col w-full justify-between'>
                <div className='w-80 pt-3'>
                  <InputForm 
                    type="text" id="proccessName" text="Nombre del proceso"
                    value={process.name}
                    onChange={handleChangeName}
                    enabled={!updatingProcess}
                  />
                  
                </div>
                <div className='w-80 pb-3 '>
                  {updatingProcess && (
                    <SpinnerText text={"Guardando..."}/>
                  )}
                  {!updatingProcess && (
                    <ButtonForm text={"Guardar"} enabled={true}  action={() => handleUpdateProcess()}/>
                  )}
                </div>
              </div>
            </section>

            <section id='proccess-parameters'>
              <div className='flex flex-col'>
              
                <div className='flex justify-between items-center my-2'>
                <span className='text-xl'>Parámetros</span>
                  <ButtonForm text={"Nuevo parámetro"} enabled={true} action={toggleEditModal} />
                </div>
                {!updatingProcess && (
                  <GenericTable
                    actions={{
                      "EDIT": (register) => {setParameterToEdit(register); toggleEditModal();},
                      "DELETE":  (register) => {setParameterToEdit(register); toggleConfirmationModal();} 
                    }}
                    fetcher={getParameters}
                    externalId = { process._id}
                    ref={tableRef} 
                  />
                )}
                
              </div>
            </section>
          </div>
        </div>
      )}
      {fetchingProcess  && (
        <div className='w-full h-full flex items-center justify-center'>
          <Spinner />
        </div>
      )}
      
    </div>
  )
}

export default ConfigSection