import React, { useEffect, useRef, useState } from 'react'
import DateRangePicker from '../../../../components/DataRangePicker'
function DashboardDataRange() {
  const boxRef = useRef(null);
  const [isDateRangeOpen, setDateRangeOpen] = useState(false);
  const [selectedDataRange, setSelectedDataRange] = useState({
    start: new Date(),
    end : new Date()
  }) 
  const handleDateRangeClick = () => {
    // Alternar el estado del selector de rango de fechas
    setDateRangeOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setDateRangeOpen(false);
    }
  };
  
  useEffect(() => {
    if (isDateRangeOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDateRangeOpen]);

  return (
    <div className='relative cursor-pointer'  >
      <div className='border-b-2 w-96 text-center basis-1/3'>
        <span className=' my-auto '  onClick={handleDateRangeClick}>{selectedDataRange.start.toLocaleDateString()} - {selectedDataRange.end.toLocaleDateString()}</span>
      </div>
      {isDateRangeOpen && (
        <div className=" absolute z-50 -left-1/3 mt-2 w-[40rem] bg-white border border-gray-300 rounded shadow-lg">
          <DateRangePicker
            onChange={(selectedRange) => { console.log(selectedRange); handleDateRangeClick()} } 
          />
        </div>
      )}
      
    </div>
  )
}

export default DashboardDataRange