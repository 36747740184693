import React, { useState } from 'react';

import StacksIco from '../../../../../components/icons/StacksIco';
import FunctionsIco from '../../../../../components/icons/FunctionsIco';
import ArrowDownwardIco from '../../../../../components/icons/ArrowDownwardIco';
import ArrowUpwardIco from '../../../../../components/icons/ArrowUpwardIco';
import SkipNextIco from '../../../../../components/icons/SkipNextIco';
import ArrowBack from '../../../../../components/icons/ArrowBack';
import ArrowForward from '../../../../../components/icons/ArrowForward';
import SkipPreviousIco from '../../../../../components/icons/SkipPreviousIco';

import ComboBox from '../../../../../components/ComboBox';

function Pagination({total, currentPage, maxPaginationPages, onChange}) {
  const totalPages = Math.ceil(total/maxPaginationPages);
  function getPagination(c, m, g) {
    console.log(c,m,g)
    // Si el número máximo de números a mostrar es mayor o igual al total de páginas, mostramos todas las páginas
    if (g >= m) {
      return Array.from({ length: m }, (_, i) => i + 1);
    }
    let start, end;
    // Si la página actual está en el principio
    if (c <= Math.floor(g / 2)) {
      start = 1;
      end = g;
    } 
    // Si la página actual está cerca del final
    else if (c + Math.floor(g / 2) >= m) {
      start = m - g + 1;
      end = m;
    } 
    // Si la página actual está en el medio
    else {
      start = c - Math.floor(g / 2);
      end = c + Math.floor(g / 2);
    }
    // Generamos el arreglo de números de página
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  let arrayPages = getPagination(currentPage,totalPages , maxPaginationPages)

  return (
    <div className='flex gap-1'>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== 1 ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(1)}>
        <SkipPreviousIco fill={currentPage !==1 ? "gray" : undefined}/>
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== 1 ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(currentPage-1 <= 1 ? 1: currentPage-1)}>
        <ArrowBack height='16px' width='16px' fill={currentPage !==1 ? "gray" : undefined}/>
      </button>
      {arrayPages.map((element,index)=> (
        <button key={index}
          className={`mx-0.5 rounded-md min-w-6 h-6 ${ element === currentPage ? 'bg-bluesens text-white' : 'bg-white hover:bg-graysens'  }`} value={element} onClick={event => onChange(event.target.value)}
        >
          {element}
        </button>
      ))}
      
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== totalPages ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(currentPage+1 >= totalPages? totalPages: currentPage+1)}>
        <ArrowForward height='16px' width='16px' fill={currentPage !==totalPages ? "gray" : undefined}/>
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== totalPages ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(totalPages)}>
        <SkipNextIco fill={currentPage !==totalPages ? "gray" : undefined}/>
      </button>
    </div>
  )
}

export default function RegistersTableWidget() {
  const [conf, setConf] = useState({
    process: {name: "Recepción MP", id: 2},
    headers: [
      {key: "batch", displayName: "Lote", color: "bluesens", stacked : true},
      {key: "op", displayName: "Orden Prod.", color: "bluesens", stacked : true},
      {key: "mp", displayName: "Materia Prima",type: "dictarray", color: "bluesens"},
      {key: "weight", displayName: "Total", type: "numeric", color: "bluesens", stacked : true},
      {key: "date", displayName: "Fecha", type: "date", color: "bluesens"},
    ],
    sortHeader: {"date": false},
    rowsNumber: 10
  });

  const [registers, setRegisters] = useState(
    [
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 17:40:00"},
      {"batch": 780 , "op": "55654-AD", "mps": [{key: "1", value: "78.5", units: "KG"}], "weight": 8902, "date": "01/08/2024 18:40:00"}
    ]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const handleStackButton = (index) => {
    let confTemp = {...conf}
    confTemp.headers[index].stacked = !confTemp.headers[index].stacked
    setConf(confTemp);
  }
  const handleSortHeader = (index) => {
    let confTemp = {...conf}
    let head = confTemp.headers[index].key
    console.log(head, confTemp)
    //ya existe como sort head
    if (typeof(confTemp.sortHeader[head]) !== "undefined"){
      if (confTemp.sortHeader[head] === true){
        //toggle a false
        confTemp.sortHeader[head] = false
      }
      else{
        //remover ordenamiento
        confTemp.sortHeader = {}
      }
    }
    else {
      confTemp.sortHeader = {}
      confTemp.sortHeader[head] = true
    }
    setConf(confTemp)
  }

  const handlePagination = (selectedPage) => {
    setCurrentPage(Number(selectedPage));
  }

  const handleRowSelection = (event) => {
    let confTemp = {...conf};
    confTemp.rowsNumber = Number(event.currentTarget.value);
    setCurrentPage(1);
  }
  return (
    <div className="overflow-auto h-full">
      <div className="min-w-full shadow-md border">
        {/* Header */}
        <div
          className="grid text-gray-500 font-semibold p-1 border-b border-gray-300 shadow"
          style={{ gridTemplateColumns: `repeat(${conf.headers.length}, minmax(0, 1fr))` }}
        >
          {conf.headers.map((headerItem, index) => (
            <div key={index} className=" p-2 mx-auto text-xs text-center">
              <div className='flex  gap-1 items-center'>
              {headerItem.type === "numeric" && (
                <button onClick={() => handleStackButton(index)}>
                  <FunctionsIco fill={headerItem.stacked ? "#0d0df7" : 'darkgray'}/>
                </button>
              )}
              {!headerItem.type &&  (
                <button onClick={() => handleStackButton(index)}>
                  <StacksIco fill={headerItem.stacked ? "#0d0df7" : 'darkgray'} />
                </button>
              )}
              {headerItem.type  && headerItem.type !== "numeric" && (<div className='w-6 h-6'></div>) }
              <button  onClick={() => handleSortHeader(index)}>{headerItem.displayName}</button>
              {typeof(conf.sortHeader[headerItem.key]) !== "undefined" ? 
                (
                  <>
                    {conf.sortHeader[headerItem.key] === true  && (
                      <ArrowUpwardIco  width='20px' height='20px' fill='darkgray'/>
                    )}
                    {conf.sortHeader[headerItem.key] === false  && (
                      <ArrowDownwardIco  width='20px' height='20px' fill='darkgray'/>
                    )}
                  </>
                ) : 
                (<div className='w-5 h-5'/>)
              }
              
              </div>
              
            </div>
          ))}
        </div>
        {/* Body */}
        <div>
          {registers.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="grid px-2 py-1 hover:bg-graysens"
              style={{ gridTemplateColumns: `repeat(${conf.headers.length}, minmax(0, 1fr))` }}
            >
              {conf.headers.map((headerItem, colIndex) => (
                <div key={colIndex} className="p-1 text-center">
                  {row[headerItem.key] ? row[headerItem.key] : "--"}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* Footer */}
        <section id="footer" className='w-100 shadow-md border-t items-center flex '>
          <div className='flex justify-between py-3 w-full'>
            <div className='mx-3 text-sm  text-gray-500'>{registers.length} total</div>
            <div className='mx-9'>
              <ComboBox
                label={"resultados por página"}
                options={[5,10,15,20,25,30]}
                onChange={handleRowSelection}
              />
            </div>
            <div className='mx-3'>
              <Pagination
                total={registers.length}
                currentPage={currentPage}
                maxPaginationPages={conf.rowsNumber}
                onChange={handlePagination}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

