import React from 'react'
import "../styles/global.css"
import Navbar from '../components/Navbar'
export default function Container(props) {
  return (
    <div className='flex flex-col min-h-screen'>
      <Navbar />
      <div className='flex flex-col flex-grow'>
        {props.children}
      </div>
    </div>
    
  )
}
